import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader2, Search, Wand2 } from 'lucide-react';
import { collection, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { getAllMakes, getModelsForMake } from '../../services/nhtsaService';
import { getVehicleSpecs } from '../../services/aiService';
import type { Vehicle, VehicleCategory, DrivetrainType, PrimaryUse } from '../../types/vehicle';

interface VehicleFormProps {
  isEditing?: boolean;
  vehicles: Vehicle[];
  setVehicles: Dispatch<SetStateAction<Vehicle[]>>;
}

interface Make {
  id: number;
  name: string;
}

interface Model {
  id: number;
  name: string;
}

const VEHICLE_CATEGORIES: VehicleCategory[] = [
  'Time Attack',
  'Drift',
  'Race',
  'Street',
  'Rally',
  'Endurance',
  'Autocross'
];

export default function VehicleForm({ isEditing, vehicles, setVehicles }: VehicleFormProps) {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [makes, setMakes] = useState<Make[]>([]);
  const [models, setModels] = useState<Model[]>([]);
  const [makeSearch, setMakeSearch] = useState('');
  const [modelSearch, setModelSearch] = useState('');
  const [selectedMakeId, setSelectedMakeId] = useState<number | null>(null);
  const [isLoadingMakes, setIsLoadingMakes] = useState(false);
  const [isLoadingModels, setIsLoadingModels] = useState(false);
  const [isMakeDropdownOpen, setIsMakeDropdownOpen] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [isLoadingSpecs, setIsLoadingSpecs] = useState(false);
  const [debouncedMakeSearch, setDebouncedMakeSearch] = useState('');

  const [formData, setFormData] = useState<Partial<Vehicle>>(
    isEditing ? {} : {
      name: '',
      make: '',
      model: '',
      year: new Date().getFullYear(),
      weight: 0,
      power: 0,
      torque: 0,
      drivetrain: 'RWD',
      fuelType: 'E85',
      primaryUse: 'Track',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }
  );

  const makeDropdownRef = useRef<HTMLDivElement>(null);
  const modelDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (makeDropdownRef.current && !makeDropdownRef.current.contains(event.target as Node)) {
        setIsMakeDropdownOpen(false);
      }
      if (modelDropdownRef.current && !modelDropdownRef.current.contains(event.target as Node)) {
        setIsModelDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function fetchVehicle() {
      if (isEditing && id && programId) {
        console.log('Fetching vehicle data...');
        try {
          const vehicleRef = doc(db, 'programs', programId, 'vehicles', id);
          const vehicleSnap = await getDoc(vehicleRef);
          
          if (vehicleSnap.exists()) {
            const vehicleData = { id: vehicleSnap.id, ...vehicleSnap.data() } as Vehicle;
            console.log('Loaded vehicle data:', vehicleData);
            setVehicle(vehicleData);
            setFormData(vehicleData);
            setMakeSearch(vehicleData.make);
            setModelSearch(vehicleData.model);
          }
        } catch (err) {
          console.error('Error fetching vehicle:', err);
          setError('Failed to load vehicle data');
        }
      }
    }

    fetchVehicle();
  }, [isEditing, id, programId]);

  useEffect(() => {
    async function fetchMakes() {
      console.log('Fetching makes...');
      setIsLoadingMakes(true);
      const makesData = await getAllMakes();
      console.log('Loaded makes:', makesData);
      setMakes(makesData);
      setIsLoadingMakes(false);
    }
    fetchMakes();
  }, []);

  useEffect(() => {
    if (vehicle?.make && makes.length > 0) {
      console.log('Finding make for:', vehicle.make);
      const findMake = makes.find(m => m.name.toLowerCase() === vehicle.make.toLowerCase());
      if (findMake) {
        console.log('Found make:', findMake);
        setSelectedMakeId(findMake.id);
      }
    }
  }, [vehicle, makes]);

  useEffect(() => {
    async function fetchModels() {
      if (selectedMakeId) {
        console.log('Fetching models for make ID:', selectedMakeId);
        setIsLoadingModels(true);
        const modelsData = await getModelsForMake(selectedMakeId);
        console.log('Loaded models:', modelsData);
        setModels(modelsData);
        setIsLoadingModels(false);
      } else {
        setModels([]);
      }
    }
    fetchModels();
  }, [selectedMakeId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedMakeSearch(makeSearch);
    }, 300);

    return () => clearTimeout(timer);
  }, [makeSearch]);

  const filteredMakes = makes
    .filter(make => make.name.toLowerCase().includes(debouncedMakeSearch.toLowerCase()))
    .sort((a, b) => {
      const aStartsWith = a.name.toLowerCase().startsWith(debouncedMakeSearch.toLowerCase());
      const bStartsWith = b.name.toLowerCase().startsWith(debouncedMakeSearch.toLowerCase());
      
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      return a.name.localeCompare(b.name);
    })
    .slice(0, 50); // Limit results to improve performance

  const filteredModels = models
    .filter(model => model.name.toLowerCase().includes(modelSearch.toLowerCase()))
    .sort((a, b) => {
      const aStartsWith = a.name.toLowerCase().startsWith(modelSearch.toLowerCase());
      const bStartsWith = b.name.toLowerCase().startsWith(modelSearch.toLowerCase());
      
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      return a.name.localeCompare(b.name);
    });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!programId) return;
    
    try {
      setIsSubmitting(true);
      const timestamp = new Date().toISOString();

      if (isEditing && id) {
        // Update existing vehicle
        const vehicleRef = doc(db, 'programs', programId, 'vehicles', id);
        const updatedVehicle = {
          ...formData,
          updatedAt: timestamp
        };
        await updateDoc(vehicleRef, updatedVehicle);
        setVehicles(prev => prev.map(v => v.id === id ? { ...v, ...updatedVehicle } : v));
      } else {
        // Check if a vehicle already exists
        if (vehicles?.length > 0) {
          setError('Only one vehicle is allowed per account');
          return;
        }

        // Create new vehicle
        const vehicleRef = collection(db, 'programs', programId, 'vehicles');
        const newVehicle = {
          ...formData,
          createdAt: timestamp,
          updatedAt: timestamp
        };
        const docRef = await addDoc(vehicleRef, newVehicle);
        setVehicles([{ id: docRef.id, ...newVehicle } as Vehicle]);
      }

      navigate('/app/vehicle');
    } catch (err) {
      console.error('Error in form submission:', err);
      setError('Failed to save vehicle');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDrivetrainChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as DrivetrainType;
    setFormData(prev => ({ ...prev, drivetrain: value }));
  };

  const handleMakeSelect = (make: Make) => {
    setFormData(prev => ({ ...prev, make: make.name }));
    setSelectedMakeId(make.id);
    setMakeSearch(make.name);
    setIsMakeDropdownOpen(false);
  };

  const handleModelSelect = (model: Model) => {
    setFormData(prev => ({ ...prev, model: model.name }));
    setModelSearch(model.name);
    setIsModelDropdownOpen(false);
    
    // Only fetch specs if we have all required fields
    if (formData.year && formData.make) {
      setIsLoadingSpecs(true);
      getVehicleSpecs(formData.year, formData.make, model.name)
        .then(specs => {
          if (specs) {
            setFormData(prev => ({
              ...prev,
              power: specs.power || prev.power,
              weight: specs.weight || prev.weight,
              drivetrain: specs.drivetrain || prev.drivetrain
            }));
          }
        })
        .catch(console.error)
        .finally(() => setIsLoadingSpecs(false));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 relative overflow-hidden">
      {/* Add subtle gradient accent */}
      <div className="absolute top-0 left-0 w-1 h-full bg-gradient-to-b from-primary-400 via-primary-500 to-primary-600 opacity-75" />
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
        {/* Basic Info */}
        <div className="space-y-4">
          <div>
            <label htmlFor="year" className="block text-sm font-medium text-gray-400">
              Year
            </label>
            <input
              type="number"
              id="year"
              value={formData.year || ''}
              onChange={(e) => {
                const year = parseInt(e.target.value);
                setFormData(prev => ({ ...prev, year }));
              }}
              className="mt-1 w-full bg-dark-100 text-white border border-dark-300 rounded-md px-3 py-2 focus:outline-none focus:border-primary-500 transition-all duration-200 hover:border-primary-400"
              placeholder="Enter year"
              min="1900"
              max={new Date().getFullYear() + 1}
            />
          </div>

          <div>
            <label htmlFor="make" className="block text-sm font-medium text-gray-400">
              Make
            </label>
            <div ref={makeDropdownRef} className="relative group">
              <input
                type="text"
                id="make"
                value={makeSearch}
                onChange={(e) => {
                  setMakeSearch(e.target.value);
                  setFormData(prev => ({ ...prev, make: e.target.value }));
                  setIsMakeDropdownOpen(true);
                }}
                onClick={() => setIsMakeDropdownOpen(true)}
                className="mt-1 w-full bg-dark-100 text-white border border-dark-300 rounded-md px-3 py-2 focus:outline-none focus:border-primary-500 transition-all duration-200 hover:border-primary-400"
                placeholder="Enter or search makes..."
              />
              {isMakeDropdownOpen && filteredMakes.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-dark-100 border border-dark-300 rounded-md shadow-lg max-h-60 overflow-auto">
                  {isLoadingMakes ? (
                    <div className="px-3 py-2 text-gray-400">Loading makes...</div>
                  ) : (
                    filteredMakes.map(make => (
                      <div
                        key={make.id}
                        className="px-3 py-2 cursor-pointer hover:bg-dark-200 transition-colors duration-150"
                        onClick={() => handleMakeSelect(make)}
                      >
                        {make.name}
                      </div>
                    ))
                  )}
                </div>
              )}
              <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <Search className="w-4 h-4" />
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="model" className="block text-sm font-medium text-gray-400">
              Model
            </label>
            <div ref={modelDropdownRef} className="relative group">
              <input
                type="text"
                id="model"
                value={modelSearch}
                onChange={(e) => {
                  setModelSearch(e.target.value);
                  setFormData(prev => ({ ...prev, model: e.target.value }));
                  setIsModelDropdownOpen(true);
                }}
                onClick={() => setIsModelDropdownOpen(true)}
                className="mt-1 w-full bg-dark-100 text-white border border-dark-300 rounded-md px-3 py-2 focus:outline-none focus:border-primary-500 transition-all duration-200 hover:border-primary-400"
                placeholder="Enter or search models..."
              />
              {isModelDropdownOpen && filteredModels.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-dark-100 border border-dark-300 rounded-md shadow-lg max-h-60 overflow-auto">
                  {isLoadingModels ? (
                    <div className="px-3 py-2 text-gray-400">Loading models...</div>
                  ) : (
                    filteredModels.map(model => (
                      <div
                        key={model.id}
                        className="px-3 py-2 cursor-pointer hover:bg-dark-200 transition-colors duration-150"
                        onClick={() => handleModelSelect(model)}
                      >
                        {model.name}
                      </div>
                    ))
                  )}
                </div>
              )}
              <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <Search className="w-4 h-4" />
              </div>
            </div>
          </div>
        </div>

        {/* Technical Specs */}
        <div className="space-y-4">
          <div className="relative group">
            <label htmlFor="power" className="block text-sm font-medium text-gray-400 group-hover:text-primary-400 transition-colors duration-200">
              Power Output (WHP)
            </label>
            <input
              type="number"
              id="power"
              value={formData.power || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, power: parseInt(e.target.value) }))}
              className="mt-1 w-full bg-dark-100 text-white border border-dark-300 rounded-md px-3 py-2 focus:outline-none focus:border-primary-500 transition-all duration-200 hover:border-primary-400"
              placeholder="Enter wheel horsepower"
            />
          </div>

          <div className="relative group">
            <label htmlFor="weight" className="block text-sm font-medium text-gray-400 group-hover:text-primary-400 transition-colors duration-200">
              Weight (lbs)
            </label>
            <input
              type="number"
              id="weight"
              value={formData.weight || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, weight: parseInt(e.target.value) }))}
              className="mt-1 w-full bg-dark-100 text-white border border-dark-300 rounded-md px-3 py-2 focus:outline-none focus:border-primary-500 transition-all duration-200 hover:border-primary-400"
              placeholder="Enter weight"
            />
          </div>

          <div>
            <label htmlFor="drivetrain" className="block text-sm font-medium text-gray-400">
              Drivetrain
            </label>
            <select
              id="drivetrain"
              value={formData.drivetrain || 'RWD'}
              onChange={handleDrivetrainChange}
              className="mt-1 w-full bg-dark-100 text-white border border-dark-300 rounded-md px-3 py-2 focus:outline-none focus:border-primary-500 transition-all duration-200 hover:border-primary-400"
            >
              <option value="RWD">RWD</option>
              <option value="FWD">FWD</option>
              <option value="AWD">AWD</option>
              <option value="4WD">4WD</option>
            </select>
          </div>

          <div>
            <label htmlFor="primaryUse" className="block text-sm font-medium text-gray-400">
              Primary Use
            </label>
            <select
              id="primaryUse"
              value={formData.primaryUse || 'Track'}
              onChange={(e) => setFormData(prev => ({ ...prev, primaryUse: e.target.value as PrimaryUse }))}
              className="mt-1 w-full bg-dark-100 text-white border border-dark-300 rounded-md px-3 py-2 focus:outline-none focus:border-primary-500 transition-all duration-200 hover:border-primary-400"
            >
              <option value="Track">Track</option>
              <option value="Race">Race</option>
              <option value="Drift">Drift</option>
              <option value="Rally">Rally</option>
              <option value="AutoX">AutoX</option>
            </select>
          </div>
        </div>
      </div>

      {error && (
        <div className="text-red-500 text-sm px-6">{error}</div>
      )}

      <div className="flex justify-end px-6 pb-6">
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-6 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 relative group"
        >
          <span className="relative z-10">
            {isSubmitting ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              'Save Vehicle'
            )}
          </span>
          <div className="absolute inset-0 bg-gradient-to-r from-primary-400 to-primary-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-md" />
        </button>
      </div>
    </form>
  );
}
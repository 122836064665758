import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { VehicleSetup, Vehicle } from '../../../types/vehicle';
import type { Part, PartMetadata, VehicleInstallations } from '../../../types/part';
import SetupSection from './SetupSection';

interface VehicleSetupProps {
  setup?: VehicleSetup;
  vehicle: Vehicle;
  parts: Part[];
  onInstallPart: (partId: string | null, slot: keyof VehicleInstallations) => Promise<void>;
  onUpdatePartMetadata: (partId: string, metadata: Partial<PartMetadata>) => Promise<void>;
  onUpdateVehicle: (updates: Partial<Vehicle>) => Promise<void>;
  onUpdateSetup: (setup: VehicleSetup) => void;
}

export function getSetupSummary(setup?: VehicleSetup) {
  if (!setup) return null;
  
  return {
    alignment: `${setup.alignment.frontCamber}° / ${setup.alignment.rearCamber}° camber`,
    suspension: `${setup.suspension.frontRideHeight}mm / ${setup.suspension.rearRideHeight}mm height`,
    tires: {
      compound: setup.tires.compound,
      pressures: `${setup.tires.frontPressureCold}/${setup.tires.rearPressureCold} psi cold`
    }
  };
}

export default function VehicleSetupComponent({ setup, vehicle, parts, onInstallPart, onUpdatePartMetadata, onUpdateVehicle, onUpdateSetup }: VehicleSetupProps) {
  const navigate = useNavigate();
  const { id: vehicleId } = useParams();

  const handleSectionClick = (category: string) => {
    navigate(`/app/vehicle/${vehicleId}/setup/${category}`);
  };

  return (
    <div className="space-y-8">
      {/* Grid Layout for Setup Sections */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
        <SetupSection
          title="Alignment"
          icon="/icons/Car Suspension.png"
          onClick={() => handleSectionClick('alignment')}
        />
        <SetupSection
          title="Suspension"
          icon="/icons/Damper.png"
          onClick={() => handleSectionClick('suspension')}
        />
        <SetupSection
          title="Aerodynamics"
          icon="/icons/Aero.png"
          onClick={() => handleSectionClick('aero')}
        />
        <SetupSection
          title="Brakes"
          icon="/icons/Disc Brake.png"
          onClick={() => handleSectionClick('brakes')}
        />
        <SetupSection
          title="Differential"
          icon="/icons/Chassis.png"
          onClick={() => handleSectionClick('differential')}
        />
        <SetupSection
          title="Gearing"
          icon="/icons/Tachometer.png"
          onClick={() => handleSectionClick('gearing')}
        />
        <SetupSection
          title="Weight"
          icon="/icons/Car Jack.png"
          onClick={() => handleSectionClick('weight')}
        />
        <SetupSection
          title="Tires"
          icon="/icons/Tire.png"
          onClick={() => handleSectionClick('tires')}
        />
        <SetupSection
          title="Fuel"
          icon="/icons/Gasoline.png"
          onClick={() => handleSectionClick('fuel')}
          isComingSoon
        />
        <SetupSection
          title="Cooling"
          icon="/icons/Radiator.png"
          onClick={() => handleSectionClick('cooling')}
        />
        <SetupSection
          title="Engine"
          icon="/icons/Car Engine.png"
          onClick={() => handleSectionClick('engine')}
        />
        <SetupSection
          title="Safety"
          icon="/icons/Seat.png"
          onClick={() => handleSectionClick('safety')}
        />
      </div>
    </div>
  );
}
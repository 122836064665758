import React from 'react';
import SetupInput from '../SetupInput';
import SetupInputGrid from '../SetupInputGrid';
import { SETUP_RANGES } from '../../../../constants/setup';
import type { VehicleSetup } from '../../../../types/vehicle';
import type { SetupChangeHandler } from '../VehicleSetup';

interface SuspensionSetupProps {
  setup: VehicleSetup['suspension'];
  onChange: SetupChangeHandler;
}

export default function SuspensionSetup({ setup, onChange }: SuspensionSetupProps) {
  return (
    <SetupInputGrid>
      <SetupInput
        label="Front Spring Rate"
        value={setup.frontSpringRate}
        onChange={(value, isTemp) => onChange('frontSpringRate', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.suspension.springRate.min}
        max={SETUP_RANGES.suspension.springRate.max}
        step={SETUP_RANGES.suspension.springRate.step}
        unit={SETUP_RANGES.suspension.springRate.unit}
      />
      <SetupInput
        label="Rear Spring Rate"
        value={setup.rearSpringRate}
        onChange={(value, isTemp) => onChange('rearSpringRate', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.suspension.springRate.min}
        max={SETUP_RANGES.suspension.springRate.max}
        step={SETUP_RANGES.suspension.springRate.step}
        unit={SETUP_RANGES.suspension.springRate.unit}
      />
      <SetupInput
        label="Front Rebound"
        value={setup.frontRebound}
        onChange={(value, isTemp) => onChange('frontRebound', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.suspension.damping.min}
        max={SETUP_RANGES.suspension.damping.max}
        step={SETUP_RANGES.suspension.damping.step}
        description="Controls how quickly the suspension extends after compression"
      />
      <SetupInput
        label="Rear Rebound"
        value={setup.rearRebound}
        onChange={(value, isTemp) => onChange('rearRebound', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.suspension.damping.min}
        max={SETUP_RANGES.suspension.damping.max}
        step={SETUP_RANGES.suspension.damping.step}
        description="Controls how quickly the suspension extends after compression"
      />
      <SetupInput
        label="Front Compression"
        value={setup.frontCompression}
        onChange={(value, isTemp) => onChange('frontCompression', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.suspension.damping.min}
        max={SETUP_RANGES.suspension.damping.max}
        step={SETUP_RANGES.suspension.damping.step}
        description="Controls how quickly the suspension compresses under load"
      />
      <SetupInput
        label="Rear Compression"
        value={setup.rearCompression}
        onChange={(value, isTemp) => onChange('rearCompression', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.suspension.damping.min}
        max={SETUP_RANGES.suspension.damping.max}
        step={SETUP_RANGES.suspension.damping.step}
        description="Controls how quickly the suspension compresses under load"
      />
    </SetupInputGrid>
  );
} 
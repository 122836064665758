import React from 'react';
import SetupInput from '../SetupInput';
import SetupInputGrid from '../SetupInputGrid';
import { SETUP_RANGES } from '../../../../constants/setup';
import type { VehicleSetup } from '../../../../types/vehicle';
import type { SetupChangeHandler } from '../VehicleSetup';

interface AlignmentSetupProps {
  setup: VehicleSetup['alignment'];
  onChange: SetupChangeHandler;
}

export default function AlignmentSetup({ setup, onChange }: AlignmentSetupProps) {
  return (
    <SetupInputGrid spacing="wide">
      <SetupInput
        label="Front Camber"
        value={setup.frontCamber}
        onChange={(value, isTemp) => onChange('frontCamber', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.alignment.camber.min}
        max={SETUP_RANGES.alignment.camber.max}
        step={SETUP_RANGES.alignment.camber.step}
        unit={SETUP_RANGES.alignment.camber.unit}
        description="Negative camber improves cornering grip but reduces straight-line traction"
      />
      <SetupInput
        label="Rear Camber"
        value={setup.rearCamber}
        onChange={(value, isTemp) => onChange('rearCamber', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.alignment.camber.min}
        max={SETUP_RANGES.alignment.camber.max}
        step={SETUP_RANGES.alignment.camber.step}
        unit={SETUP_RANGES.alignment.camber.unit}
        description="Negative camber improves cornering grip but reduces straight-line traction"
      />
      <SetupInput
        label="Front Toe"
        value={setup.frontToe}
        onChange={(value, isTemp) => onChange('frontToe', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.alignment.toe.min}
        max={SETUP_RANGES.alignment.toe.max}
        step={SETUP_RANGES.alignment.toe.step}
        unit={SETUP_RANGES.alignment.toe.unit}
        description="Toe-in improves stability, toe-out improves turn-in response"
      />
      <SetupInput
        label="Rear Toe"
        value={setup.rearToe}
        onChange={(value, isTemp) => onChange('rearToe', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.alignment.toe.min}
        max={SETUP_RANGES.alignment.toe.max}
        step={SETUP_RANGES.alignment.toe.step}
        unit={SETUP_RANGES.alignment.toe.unit}
        description="Toe-in improves stability, toe-out improves turn-in response"
      />
      <SetupInput
        label="Caster"
        value={setup.caster}
        onChange={(value, isTemp) => onChange('caster', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.alignment.caster.min}
        max={SETUP_RANGES.alignment.caster.max}
        step={SETUP_RANGES.alignment.caster.step}
        unit={SETUP_RANGES.alignment.caster.unit}
        description="More caster increases straight-line stability and improves camber gain in turns"
      />
    </SetupInputGrid>
  );
} 
export const SETUP_RANGES = {
  alignment: {
    camber: { min: -10, max: 10, step: 0.1, unit: '°' },
    toe: { min: -5, max: 5, step: 0.1, unit: '°' },
    caster: { min: 0, max: 15, step: 0.1, unit: '°' }
  },
  suspension: {
    springRate: { min: 200, max: 2000, step: 50, unit: 'lb/in' },
    damping: { min: 1, max: 20, step: 1 },
    barSetting: { min: 1, max: 10, step: 1 }
  },
  aero: {
    wingAngle: { min: 0, max: 35, step: 0.5, unit: '°' },
    splitterHeight: { min: 0, max: 100, step: 1, unit: 'mm' }
  },
  tires: {
    pressure: { min: 20, max: 50, step: 0.5, unit: 'psi' }
  },
  brakes: {
    bias: { min: 40, max: 80, step: 0.1, unit: '%' }
  },
  differential: {
    preload: { min: 0, max: 200, step: 1, unit: 'Nm' },
    rampAngle: { min: 30, max: 90, step: 1, unit: '°' },
    lockingPercentage: { min: 0, max: 100, step: 1, unit: '%' }
  },
  gearing: {
    finalDrive: { min: 2, max: 6, step: 0.01 },
    ratio: { min: 0.5, max: 5, step: 0.001 }
  },
  weight: {
    ballast: { min: 0, max: 200, step: 1, unit: 'lb' }
  },
  cooling: {
    temperature: { min: 60, max: 110, step: 1, unit: '°C' }
  },
  engine: {
    idleRpm: { min: 600, max: 1200, step: 50, unit: 'RPM' },
    revLimit: { min: 4000, max: 9000, step: 100, unit: 'RPM' },
    fuelPressure: { min: 2.0, max: 5.0, step: 0.1, unit: 'bar' },
    boostPressure: { min: 0, max: 2.0, step: 0.1, unit: 'bar' }
  },
  safety: {
    harnessExpiration: { type: 'date' },
    seatExpiration: { type: 'date' },
    helmetExpiration: { type: 'date' },
    glovesExpiration: { type: 'date' },
    suitExpiration: { type: 'date' },
    handsRestraintExpiration: { type: 'date' },
    neckRestraintExpiration: { type: 'date' }
  }
} as const;

export const TIRE_COMPOUNDS = [
  { value: 100, label: 'R1 - Competition (100)' },
  { value: 200, label: 'R2 - Track Day (200)' },
  { value: 300, label: 'Street Performance (300)' },
  { value: 400, label: 'All Season (400)' }
];

export const BRAKE_PAD_TYPES = [
  { value: 'race', label: 'Race/Competition' },
  { value: 'track', label: 'Track Day' },
  { value: 'street', label: 'Street Performance' },
  { value: 'oem', label: 'OEM/Stock' }
];

export const DRIVETRAIN_TYPES = [
  { value: 'FWD', label: 'Front Wheel Drive' },
  { value: 'RWD', label: 'Rear Wheel Drive' },
  { value: 'AWD', label: 'All Wheel Drive' },
  { value: '4WD', label: 'Four Wheel Drive' }
]; 
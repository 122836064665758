import React from 'react';
import SetupInput from '../SetupInput';
import SetupInputGrid from '../SetupInputGrid';
import type { VehicleSetup } from '../../../../types/vehicle';

interface SafetySetupProps {
  setup: {
    harnessExpiration: string;
    seatExpiration: string;
    helmetExpiration: string;
    glovesExpiration: string;
    suitExpiration: string;
    handsRestraintExpiration: string;
    neckRestraintExpiration: string;
  };
  onChange: (field: string, value: string | number, isTemp?: boolean) => void;
}

export default function SafetySetup({ setup, onChange }: SafetySetupProps) {
  // Get today's date in YYYY-MM-DD format for min date
  const today = new Date().toISOString().split('T')[0];
  // Max date 10 years from now
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 10);
  const maxDateStr = maxDate.toISOString().split('T')[0];

  return (
    <SetupInputGrid spacing="wide">
      <SetupInput
        label="Harness Expiration"
        value={setup.harnessExpiration}
        onChange={(value, isTemp) => onChange('harnessExpiration', value as string, isTemp)}
        type="date"
        min={today}
        max={maxDateStr}
        width="w-48"
        description="SFI harnesses typically expire 2 years from date of manufacture, FIA 5 years"
      />
      <SetupInput
        label="Seat Expiration"
        value={setup.seatExpiration}
        onChange={(value, isTemp) => onChange('seatExpiration', value as string, isTemp)}
        type="date"
        min={today}
        max={maxDateStr}
        width="w-48"
        description="FIA seats typically expire 5 years from date of manufacture"
      />
      <SetupInput
        label="Helmet Expiration"
        value={setup.helmetExpiration}
        onChange={(value, isTemp) => onChange('helmetExpiration', value as string, isTemp)}
        type="date"
        min={today}
        max={maxDateStr}
        width="w-48"
        description="Snell helmets expire every 11 years from standard release, FIA 10 years from manufacture"
      />
      <SetupInput
        label="Racing Suit Expiration"
        value={setup.suitExpiration}
        onChange={(value, isTemp) => onChange('suitExpiration', value as string, isTemp)}
        type="date"
        min={today}
        max={maxDateStr}
        width="w-48"
        description="SFI suits require recertification every 5 years, FIA 10 years from manufacture"
      />
      <SetupInput
        label="Racing Gloves Expiration"
        value={setup.glovesExpiration}
        onChange={(value, isTemp) => onChange('glovesExpiration', value as string, isTemp)}
        type="date"
        min={today}
        max={maxDateStr}
        width="w-48"
        description="SFI/FIA gloves typically expire 5 years from manufacture"
      />
      <SetupInput
        label="Hand Restraint Expiration"
        value={setup.handsRestraintExpiration}
        onChange={(value, isTemp) => onChange('handsRestraintExpiration', value as string, isTemp)}
        type="date"
        min={today}
        max={maxDateStr}
        width="w-48"
        description="Arm restraints typically expire 2 years from date of manufacture"
      />
      <SetupInput
        label="Neck Restraint Expiration"
        value={setup.neckRestraintExpiration}
        onChange={(value, isTemp) => onChange('neckRestraintExpiration', value as string, isTemp)}
        type="date"
        min={today}
        max={maxDateStr}
        width="w-48"
        description="HANS/neck restraints typically require recertification every 5 years"
      />
    </SetupInputGrid>
  );
} 

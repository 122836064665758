import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { ExternalLink, Loader2, Calendar, CheckCircle2, Clock } from 'lucide-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Navigate } from 'react-router-dom';
import { format } from 'date-fns';

export default function Subscription() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  // Only active subscribers can access this page
  if (!user?.subscription?.status || user.subscription.status !== 'active') {
    return <Navigate to="/login" replace />;
  }

  const handleManageSubscription = async () => {
    setIsLoading(true);
    try {
      const functions = getFunctions();
      const createPortalSession = httpsCallable(functions, 'createPortalSession');
      const { data } = await createPortalSession();
      window.location.href = (data as { url: string }).url;
    } catch (error) {
      console.error('Error creating portal session:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-white">Subscription</h1>

      <div className="bg-gradient-to-br from-dark-200 to-dark-300 rounded-lg p-6 shadow-lg border border-dark-50">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-lg font-semibold text-white">Current Plan</h2>
          <div className="flex items-center gap-2 px-3 py-1 bg-green-500/10 text-green-400 rounded-full">
            <CheckCircle2 className="w-4 h-4" />
            <span className="text-sm font-medium">Active</span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="flex items-start gap-4">
              <Calendar className="w-5 h-5 text-primary-500 mt-1" />
              <div>
                <p className="text-sm text-gray-400">Member Since</p>
                <p className="text-lg font-medium text-white">
                  {user.createdAt ? 
                    format(new Date(user.createdAt), 'MMMM d, yyyy') :
                    'N/A'
                  }
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <Clock className="w-5 h-5 text-primary-500 mt-1" />
              <div>
                <p className="text-sm text-gray-400">Renews On</p>
                <p className="text-lg font-medium text-white">
                  {user.subscription.currentPeriodEnd ? 
                    format(new Date(user.subscription.currentPeriodEnd), 'MMMM d, yyyy') :
                    'N/A'
                  }
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-end justify-end md:border-l md:border-dark-50 md:pl-8">
            <button
              onClick={handleManageSubscription}
              disabled={isLoading}
              className="inline-flex items-center gap-2 px-6 py-3 bg-primary-500 text-white rounded-md hover:bg-primary-400 disabled:opacity-50 transition-colors duration-200"
            >
              {isLoading ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  Manage Subscription
                  <ExternalLink className="w-4 h-4" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
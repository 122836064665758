import React from 'react';

interface SetupSectionProps {
  title: string;
  icon: string;
  onClick: () => void;
  isComingSoon?: boolean;
}

export default function SetupSection({ title, icon, onClick, isComingSoon }: SetupSectionProps) {
  return (
    <button
      onClick={onClick}
      className={`
        flex flex-col items-center justify-center p-5 rounded-lg transition-colors aspect-square
        ${isComingSoon 
          ? 'bg-dark-100 cursor-not-allowed opacity-50'
          : 'bg-dark-100 hover:bg-dark-50'
        }
      `}
      disabled={isComingSoon}
    >
      <img src={icon} alt={title} className="w-24 h-24 mb-2" />
      <span className="text-sm font-medium text-white">{title}</span>
      {isComingSoon && (
        <span className="text-xs text-gray-400 mt-1">Coming Soon</span>
      )}
    </button>
  );
} 
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';

const stripePromise = loadStripe('pk_test_51LK6S0DXsyBV2tv6XPlpRJFuuik3ZsamoooFAbwqRYqAtOOem7Xlo9eObYQTLn03V1LPfA87eTY5Al8q16pBwIKJ00eRABBCrG');

export async function redirectToCheckout() {
  console.log('Starting checkout redirect process...');
  try {
    const functions = getFunctions();
    const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');

    console.log('Creating checkout session...');
    const { data } = await createCheckoutSession();
    
    const { url } = data as { url: string };
    console.log('Got checkout URL:', url);
    
    window.location.href = url;
  } catch (error) {
    console.error('Error redirecting to checkout:', error);
    throw error;
  }
} 
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Bell, Layout, Wrench, Info, Download } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { APP_VERSION, BUILD_TIMESTAMP } from '../version';

interface DisplayPreferences {
  vehicleView: 'grid' | 'list';
  dashboardSections: {
    upcomingEvents: boolean;
    maintenanceTasks: boolean;
    vehicleStatus: boolean;
    budgetOverview: boolean;
  };
}

interface TaskPreferences {
  defaultPriority: 'low' | 'medium' | 'high';
  sortBy: 'priority' | 'date' | 'difficulty';
  viewLayout: 'board' | 'list';
}

interface UserSettings {
  displayPreferences: DisplayPreferences;
  taskPreferences: TaskPreferences;
}

const defaultSettings: UserSettings = {
  displayPreferences: {
    vehicleView: 'grid',
    dashboardSections: {
      upcomingEvents: true,
      maintenanceTasks: true,
      vehicleStatus: true,
      budgetOverview: true
    }
  },
  taskPreferences: {
    defaultPriority: 'medium',
    sortBy: 'priority',
    viewLayout: 'board'
  }
};

export default function Settings() {
  const { user, programId } = useAuth();
  const [settings, setSettings] = useState<UserSettings>(defaultSettings);
  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user || !programId) {
      setSettings(defaultSettings);
      setIsLoading(false);
      return;
    }

    const loadSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'programs', programId, 'settings', 'preferences'));
        
        if (settingsDoc.exists()) {
          setSettings(settingsDoc.data() as UserSettings);
        } else {
          await setDoc(doc(db, 'programs', programId, 'settings', 'preferences'), defaultSettings);
          setSettings(defaultSettings);
        }
        setError(null);
      } catch (err) {
        console.error('Error loading settings:', err);
        setError('Failed to load settings');
      } finally {
        setIsLoading(false);
      }
    };

    loadSettings();
  }, [user, programId]);

  const updateSettings = async (newSettings: Partial<UserSettings>) => {
    if (!user || !programId) {
      toast.error('No active program');
      return;
    }

    try {
      const updatedSettings = {
        ...settings,
        ...newSettings
      };

      await setDoc(doc(db, 'programs', programId, 'settings', 'preferences'), updatedSettings);
      setSettings(updatedSettings);
      toast.success('Settings updated');
    } catch (err) {
      console.error('Error updating settings:', err);
      toast.error('Failed to update settings');
    }
  };

  const handleDisplayPreferenceChange = (key: keyof DisplayPreferences, value: any) => {
    updateSettings({
      displayPreferences: {
        ...settings.displayPreferences,
        [key]: value
      }
    });
  };

  const handleDashboardSectionChange = (key: keyof typeof settings.displayPreferences.dashboardSections) => {
    updateSettings({
      displayPreferences: {
        ...settings.displayPreferences,
        dashboardSections: {
          ...settings.displayPreferences.dashboardSections,
          [key]: !settings.displayPreferences.dashboardSections[key]
        }
      }
    });
  };

  const handleTaskPreferenceChange = (key: keyof TaskPreferences, value: string) => {
    updateSettings({
      taskPreferences: {
        ...settings.taskPreferences,
        [key]: value
      }
    });
  };

  const exportData = async () => {
    if (!programId) {
      toast.error('No active program');
      return;
    }

    setIsExporting(true);
    try {
      // Fetch all collections
      const collections = ['vehicles', 'events', 'tasks', 'checklists', 'parts', 'budget', 'settings'];
      const exportData: Record<string, any> = {};

      for (const collectionName of collections) {
        const collectionRef = collection(db, 'programs', programId, collectionName);
        const snapshot = await getDocs(collectionRef);
        exportData[collectionName] = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      }

      // Create and download the file
      const dataStr = JSON.stringify(exportData, null, 2);
      const blob = new Blob([dataStr], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `pitprep-export-${new Date().toISOString().split('T')[0]}.json`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      toast.success('Data exported successfully');
    } catch (err) {
      console.error('Error exporting data:', err);
      toast.error('Failed to export data');
    } finally {
      setIsExporting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-white">Settings</h1>

      {error && (
        <div className="bg-red-500/10 text-red-500 p-4 rounded-md">
          {error}
        </div>
      )}

      {/* Display Preferences */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex items-center gap-2 mb-6">
          <Layout className="w-5 h-5 text-primary-500" />
          <h2 className="text-lg font-semibold text-white">Display Preferences</h2>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-white mb-2">Vehicle View</label>
            <select
              value={settings.displayPreferences.vehicleView}
              onChange={(e) => handleDisplayPreferenceChange('vehicleView', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
            >
              <option value="grid">Grid</option>
              <option value="list">List</option>
            </select>
          </div>

          <div>
            <label className="block text-white mb-2">Dashboard Sections</label>
            <div className="space-y-2">
              {Object.entries(settings.displayPreferences.dashboardSections).map(([key, value]) => (
                <label key={key} className="flex items-center justify-between">
                  <span className="text-gray-400">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</span>
                  <input
                    type="checkbox"
                    checked={value}
                    onChange={() => handleDashboardSectionChange(key as keyof typeof settings.displayPreferences.dashboardSections)}
                    className="sr-only peer"
                  />
                  <div className="relative w-11 h-6 bg-dark-100 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-500/20 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-500"></div>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Task Management */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex items-center gap-2 mb-6">
          <Bell className="w-5 h-5 text-primary-500" />
          <h2 className="text-lg font-semibold text-white">Task Management</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-white mb-2">Default Priority</label>
            <select
              value={settings.taskPreferences.defaultPriority}
              onChange={(e) => handleTaskPreferenceChange('defaultPriority', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
          </div>

          <div>
            <label className="block text-white mb-2">Sort Tasks By</label>
            <select
              value={settings.taskPreferences.sortBy}
              onChange={(e) => handleTaskPreferenceChange('sortBy', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
            >
              <option value="priority">Priority</option>
              <option value="date">Due Date</option>
              <option value="difficulty">Difficulty</option>
            </select>
          </div>

          <div>
            <label className="block text-white mb-2">Default View</label>
            <select
              value={settings.taskPreferences.viewLayout}
              onChange={(e) => handleTaskPreferenceChange('viewLayout', e.target.value)}
              className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
            >
              <option value="board">Board</option>
              <option value="list">List</option>
            </select>
          </div>
        </div>
      </div>

      {/* App Information */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex items-center gap-2 mb-6">
          <Info className="w-5 h-5 text-primary-500" />
          <h2 className="text-lg font-semibold text-white">App Information</h2>
        </div>

        <div className="space-y-4">
          <div className="flex justify-between items-center py-2 border-b border-dark-50">
            <span className="text-gray-400">Version</span>
            <span className="text-white">{APP_VERSION}</span>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-dark-50">
            <span className="text-gray-400">Build Date</span>
            <span className="text-white">{new Date(BUILD_TIMESTAMP).toLocaleDateString()}</span>
          </div>

          {/* Legal Documents */}
          <div className="flex justify-between items-center py-2 border-b border-dark-50">
            <span className="text-gray-400">Legal</span>
            <div className="space-x-4">
              <a
                href="/legal/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-500 hover:text-primary-400 text-sm"
              >
                Privacy Policy
              </a>
              <a
                href="/legal/terms-of-service.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-500 hover:text-primary-400 text-sm"
              >
                Terms of Service
              </a>
            </div>
          </div>

          <button
            onClick={exportData}
            disabled={isExporting}
            className="w-full mt-4 inline-flex items-center justify-center gap-2 px-4 py-2 border border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white rounded-md disabled:opacity-50 transition-colors"
          >
            {isExporting ? (
              <>
                <Bell className="w-4 h-4 animate-spin" />
                Exporting...
              </>
            ) : (
              <>
                <Download className="w-4 h-4" />
                Export All Data
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { Slider } from '../../ui/Slider';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/Select';
import { RadioGroup, RadioGroupItem } from '../../ui/RadioGroup';

interface SetupInputProps {
  label: string;
  value: number | string;
  onChange: (value: number | string, isTemporary?: boolean) => void;
  type?: 'text' | 'number' | 'slider' | 'select' | 'radio' | 'date';
  min?: number | string;
  max?: number | string;
  step?: number;
  unit?: string;
  width?: string;
  options?: Array<{ value: string | number; label: string }>;
  description?: string;
}

export default function SetupInput({
  label,
  value,
  onChange,
  type = 'number',
  min,
  max,
  step = 1,
  unit,
  width = 'w-24',
  options = [],
  description
}: SetupInputProps) {
  const renderInput = () => {
    switch (type) {
      case 'date':
        return (
          <input
            type="date"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            min={min as string}
            max={max as string}
            className={`${width} bg-dark-200 text-white border border-dark-300 rounded px-2 py-1 focus:outline-none focus:border-primary-500`}
          />
        );

      case 'slider':
        const [localValue, setLocalValue] = React.useState(value);
        return (
          <div className="flex-1">
            <input
              type="range"
              value={localValue}
              onChange={(e) => setLocalValue(Number(e.target.value))}
              onMouseUp={(e) => onChange(Number((e.target as HTMLInputElement).value))}
              onTouchEnd={(e) => onChange(Number((e.target as HTMLInputElement).value))}
              min={min as number}
              max={max as number}
              step={step}
              className="w-full"
            />
            <div className="flex justify-between mt-1 text-xs text-gray-400">
              <span>{min}{unit}</span>
              <span>{max}{unit}</span>
            </div>
          </div>
        );

      case 'select':
        return (
          <Select value={value.toString()} onValueChange={(val: string) => onChange(val)}>
            <SelectTrigger className="w-full bg-dark-200 border-dark-300">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {options.map((option) => (
                <SelectItem key={option.value} value={option.value.toString()}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );

      case 'radio':
        return (
          <RadioGroup value={value.toString()} onValueChange={(val: string) => onChange(val)}>
            <div className="grid grid-cols-2 gap-2">
              {options.map((option) => (
                <div key={option.value} className="flex items-center space-x-2">
                  <RadioGroupItem value={option.value.toString()} id={option.value.toString()} />
                  <label htmlFor={option.value.toString()} className="text-sm text-gray-400">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </RadioGroup>
        );

      case 'text':
        return (
          <input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={`${width} bg-dark-200 text-white border border-dark-300 rounded px-2 py-1 focus:outline-none focus:border-primary-500`}
          />
        );

      default:
        return (
          <div className="flex items-center gap-2">
            <input
              type="number"
              value={value}
              onChange={(e) => onChange(Number(e.target.value))}
              step={step}
              min={min}
              max={max}
              className={`${width} bg-dark-200 text-white border border-dark-300 rounded px-2 py-1 focus:outline-none focus:border-primary-500`}
            />
            {unit && <span className="text-sm text-gray-400">{unit}</span>}
          </div>
        );
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <label className="text-sm font-medium text-gray-400">{label}</label>
        {type === 'slider' && (
          <div className="flex items-center gap-1">
            <span className="text-sm text-white">{Number(value).toFixed(step < 1 ? 1 : 0)}</span>
            {unit && <span className="text-sm text-gray-400">{unit}</span>}
          </div>
        )}
      </div>
      {renderInput()}
      {description && (
        <p className="text-xs text-gray-500">{description}</p>
      )}
    </div>
  );
} 
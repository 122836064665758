import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";
import { getAnalytics } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCMx8Va1hQfo6t42aprQoKyCdIys-mzp3k",
  authDomain: "pit-prep.firebaseapp.com",
  projectId: "pit-prep",
  storageBucket: "pit-prep.firebasestorage.app",
  messagingSenderId: "282878628961",
  appId: "1:282878628961:web:216ad3d99304d9a4ce8620",
  measurementId: "G-HYWRZCDMSF"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
export const analytics = getAnalytics(app);

// Initialize Firebase Auth
export const auth = getAuth(app);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Vertex AI
export const vertexAI = getVertexAI(
  app,
  {
    location: "us-central1",
  }
);

// Initialize Gemini Model
export const geminiModel = getGenerativeModel(vertexAI, {
  model: "gemini-1.5-flash"
}); 
import React, { useState, useRef } from 'react';
import { Plus, Loader2, ChevronDown, Trash2, ArrowUpDown, ArrowUp, ArrowDown, Pencil } from 'lucide-react';
import { doc, updateDoc, addDoc, collection, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { getPartInfo } from '../../services/aiService';
import type { Part, PartCategory, PartSubcategory } from '../../types/part';
import { AddPartForm } from './AddPartForm';
import type { PartTemplate } from '../../config/partCategories';
import { commonParts } from '../../config/partCategories';
import type { Vehicle } from '../../types/vehicle';

interface PartsTableProps {
  parts: Part[];
  vehicleId?: string;
  showQuickAdd?: boolean;
  mode: 'inventory' | 'installed';
  vehicle?: Vehicle;
}

type SortField = 'name' | 'category' | 'subcategory' | 'cost' | 'quantity' | 'condition' | 'manufacturer';
type SortDirection = 'asc' | 'desc';

const PART_CATEGORIES: PartCategory[] = [
  'Brakes',
  'Tires',
  'Engine',
  'Suspension',
  'Transmission',
  'Exterior',
  'Interior',
  'Electrical',
  'Cooling',
  'Fuel System',
  'Exhaust',
  'Drivetrain',
  'Other'
];

const PART_CONDITIONS = ['new', 'used', 'refurbished'] as const;

const PART_SUBCATEGORIES: Record<PartCategory, string[]> = {
  'Brakes': ['Pads', 'Rotors', 'Fluid', 'Lines', 'Calipers', 'Master Cylinder', 'Brake Ducts'],
  'Tires': ['Summer', 'Winter', 'All Season', 'Track', 'Rain'],
  'Engine': ['Oil', 'Filter', 'Spark Plugs', 'Belts', 'Timing Components', 'Intake', 'Turbo', 'Supercharger', 'Engine Management'],
  'Suspension': ['Coilovers', 'Springs', 'Shocks', 'Sway Bars', 'Control Arms', 'Bushings', 'Alignment Parts', 'Wheels'],
  'Transmission': ['Fluid', 'Clutch', 'Flywheel', 'Shifter', 'Differential', 'Gears'],
  'Exterior': ['Aero', 'Body Panels', 'Lighting', 'Paint', 'Wraps'],
  'Interior': ['Seats', 'Harnesses', 'Roll Cage', 'Gauges', 'Electronics'],
  'Electrical': ['Battery', 'Alternator', 'Starter', 'Wiring', 'Sensors'],
  'Cooling': ['Radiator', 'Oil Cooler', 'Intercooler', 'Fans', 'Hoses'],
  'Fuel System': ['Pump', 'Filter', 'Injectors', 'Lines', 'Tank'],
  'Exhaust': ['Headers', 'Catalytic Converter', 'Muffler', 'Full System'],
  'Drivetrain': ['Axles', 'Driveshaft', 'CV Joints', 'Transfer Case'],
  'Steering': ['Fluid'],
  'Other': ['Miscellaneous']
};

const normalizeString = (str: string) => 
  str.toLowerCase().replace(/\s+/g, '');

export default function PartsTable({ parts, vehicleId, showQuickAdd = true, mode, vehicle }: PartsTableProps) {
  const { programId } = useAuth();
  const [newPartName, setNewPartName] = useState('');
  const [isAddingPart, setIsAddingPart] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState<PartTemplate | null>(null);
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [editingStates, setEditingStates] = useState<Record<string, Partial<Part>>>({});
  const inputRef = useRef<HTMLInputElement>(null);
  const isUpdatingRef = useRef<Record<string, boolean>>({});

  const handleUpdatePart = async (partId: string, updates: Partial<Part>) => {
    if (!programId || isUpdatingRef.current[partId]) return;
    try {
      isUpdatingRef.current[partId] = true;
      const partsRef = doc(db, 'programs', programId, 'parts', partId);
      await updateDoc(partsRef, {
        ...updates,
        updatedAt: new Date().toISOString()
      });
      // Clear the editing state for this part
      setEditingStates(prev => {
        const newState = { ...prev };
        delete newState[partId];
        return newState;
      });
      toast.success('Part updated successfully');
    } catch (err) {
      console.error('Error updating part:', err);
      toast.error('Failed to update part');
    } finally {
      setTimeout(() => {
        isUpdatingRef.current[partId] = false;
      }, 100);
    }
  };

  const handleFieldChange = (partId: string, field: keyof Part, value: any) => {
    // Just update local state
    setEditingStates(prev => ({
      ...prev,
      [partId]: {
        ...(prev[partId] || {}),
        [field]: value
      }
    }));
  };

  const handleFieldBlur = async (partId: string) => {
    // Only update if there are changes and not already updating
    if (editingStates[partId] && !isUpdatingRef.current[partId]) {
      await handleUpdatePart(partId, editingStates[partId]);
    }
  };

  const handleFieldKeyDown = async (e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>, partId: string) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      if (editingStates[partId]) {
        await handleUpdatePart(partId, editingStates[partId]);
        (e.target as HTMLElement).blur();
      }
    }
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const getSortIcon = (field: SortField) => {
    if (sortField !== field) return <ArrowUpDown className="w-4 h-4 opacity-0 group-hover:opacity-100" />;
    if (sortDirection === 'asc') return <ArrowUp className="w-4 h-4" />;
    return <ArrowDown className="w-4 h-4" />;
  };

  const sortedParts = [...parts].sort((a, b) => {
    const direction = sortDirection === 'asc' ? 1 : -1;
    
    switch (sortField) {
      case 'name':
        return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) * direction;
      case 'category':
        return (a.category > b.category ? 1 : -1) * direction;
      case 'subcategory':
        const subA = a.subcategory || '';
        const subB = b.subcategory || '';
        return (subA > subB ? 1 : -1) * direction;
      case 'cost':
        return (a.cost - b.cost) * direction;
      case 'quantity':
        return (a.quantity - b.quantity) * direction;
      case 'condition':
        return (a.condition > b.condition ? 1 : -1) * direction;
      case 'manufacturer':
        const manuA = a.manufacturer?.toLowerCase() || '';
        const manuB = b.manufacturer?.toLowerCase() || '';
        return (manuA > manuB ? 1 : -1) * direction;
      default:
        return 0;
    }
  });

  const handleTemplateSelect = (template: PartTemplate) => {
    setActiveSuggestion(template);
    // Scroll to input smoothly
    inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // Focus the input
    inputRef.current?.focus();
  };

  const handleQuickAddPart = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newPartName.trim() && programId && vehicle) {
      setIsAddingPart(true);
      try {
        const partInfo = await getPartInfo(newPartName.trim(), vehicle);
        
        const template = commonParts.find(p => 
          p.category === partInfo.category && 
          p.subcategory === partInfo.subcategory
        );
        
        const partData: Partial<Part> = {
          name: partInfo.name || newPartName.trim(),
          category: partInfo.category as PartCategory,
          subcategory: partInfo.subcategory as PartSubcategory,
          cost: partInfo.cost || 0,
          description: partInfo.description || '',
          manufacturer: partInfo.manufacturer || '',
          quantity: 1,
          condition: 'new',
          vehicleId: vehicleId,
          metadata: template?.metadata || {},
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        const partsRef = collection(db, 'programs', programId, 'parts');
        await addDoc(partsRef, partData);
        
        setNewPartName('');
        toast.success('Part added with AI-suggested details');
      } catch (err) {
        console.error('Error adding part:', err);
        toast.error('Failed to add part');
      } finally {
        setIsAddingPart(false);
      }
    }
  };

  const handleAddPart = async (formData: Partial<Part>) => {
    if (!programId) return;

    try {
      const partData: Partial<Part> = {
        ...formData,
        vehicleId: vehicleId || null,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      const partsRef = collection(db, 'programs', programId, 'parts');
      await addDoc(partsRef, partData);
      
      toast.success('Part added successfully');
    } catch (err) {
      console.error('Error adding part:', err);
      toast.error('Failed to add part');
    }
  };

  const handleDeletePart = async (partId: string) => {
    if (!programId) return;
    
    try {
      const partRef = doc(db, 'programs', programId, 'parts', partId);
      await deleteDoc(partRef);
      toast.success('Part deleted successfully');
    } catch (err) {
      console.error('Error deleting part:', err);
      toast.error('Failed to delete part');
    }
  };

  const getEditableFieldClass = (isEditing: boolean) => `
    group/field relative w-full bg-transparent 
    text-white rounded-md transition-colors
    ${isEditing ? 'bg-dark-200 ring-1 ring-primary-500' : 'hover:bg-dark-200/50'}
    focus-within:bg-dark-200 focus-within:ring-1 focus-within:ring-primary-500
  `;

  const getInputClass = `
    w-full bg-transparent border-none px-2 py-1.5
    text-white placeholder-gray-500
    focus:ring-0 focus:outline-none
    [&::-webkit-inner-spin-button]:appearance-none
    [&::-webkit-outer-spin-button]:appearance-none
    [-moz-appearance:textfield]
  `;

  const getSelectClass = `
    ${getInputClass}
    appearance-none cursor-pointer
    pr-8 /* Space for custom arrow */
    [&>option]:bg-dark-200 [&>option]:text-white
    [&>option]:py-1 [&>option]:px-2
  `;

  return (
    <div className="space-y-6">
      {showQuickAdd && (
        <div className="relative space-y-2">
          <div className="flex gap-2">
            <input
              ref={inputRef}
              type="text"
              value={newPartName}
              onChange={(e) => setNewPartName(e.target.value)}
              onKeyDown={handleQuickAddPart}
              placeholder="Enter part details..."
              className="flex-1 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-2 focus:ring-primary-500"
              disabled={isAddingPart}
            />
            <button
              onClick={() => {
                if (newPartName.trim()) {
                  handleQuickAddPart({ key: 'Enter' } as React.KeyboardEvent<HTMLInputElement>);
                }
              }}
              disabled={isAddingPart || !newPartName.trim()}
              className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
            >
              {isAddingPart ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <Plus className="w-4 h-4" />
              )}
              Add Part
            </button>
          </div>
          
          {/* Suggestion hint */}
          {activeSuggestion && (
            <div className="text-sm text-gray-400 animate-fadeIn">
              <div className="flex items-center gap-2">
                <div className="w-1 h-4 bg-primary-500 rounded-full"></div>
                <p>
                  Adding {activeSuggestion.name.toLowerCase()}? Include the brand, model, and any specific details 
                  that will help track maintenance.
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      {parts.length === 0 ? (
        mode === 'installed' ? (
          <div className="text-center py-12 bg-dark-200 rounded-lg">
            <p className="text-gray-400">No parts installed.</p>
            <p className="mt-2 text-sm text-gray-500">
              Add parts to track their maintenance and wear.
            </p>
          </div>
        ) : (
          <div className="text-center py-12 bg-dark-200 rounded-lg">
            <p className="text-gray-400">No parts in inventory.</p>
            <p className="mt-2 text-sm text-gray-500">
              Type a part name above and press Enter to add it to inventory.
            </p>
          </div>
        )
      ) : (
        <>
          <div className="bg-dark-300 rounded-lg overflow-hidden">
            {/* Desktop View */}
            <table className="w-full hidden lg:table">
              <thead>
                <tr className="bg-primary-500/10 border-b border-dark-200">
                  <th 
                    className="px-6 py-4 text-left text-sm font-semibold text-primary-500 cursor-pointer group"
                    onClick={() => handleSort('name')}
                  >
                    <div className="flex items-center gap-2">
                      Name
                      {getSortIcon('name')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-4 text-left text-sm font-semibold text-primary-500 cursor-pointer group"
                    onClick={() => handleSort('manufacturer')}
                  >
                    <div className="flex items-center gap-2">
                      Manufacturer
                      {getSortIcon('manufacturer')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-4 text-left text-sm font-semibold text-primary-500 cursor-pointer group"
                    onClick={() => handleSort('category')}
                  >
                    <div className="flex items-center gap-2">
                      Category
                      {getSortIcon('category')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-4 text-left text-sm font-semibold text-primary-500 cursor-pointer group"
                    onClick={() => handleSort('subcategory')}
                  >
                    <div className="flex items-center gap-2">
                      Subcategory
                      {getSortIcon('subcategory')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-4 text-left text-sm font-semibold text-primary-500 cursor-pointer group"
                    onClick={() => handleSort('cost')}
                  >
                    <div className="flex items-center gap-2">
                      Cost
                      {getSortIcon('cost')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-4 text-left text-sm font-semibold text-primary-500 cursor-pointer group"
                    onClick={() => handleSort('quantity')}
                  >
                    <div className="flex items-center gap-2">
                      Quantity
                      {getSortIcon('quantity')}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-4 text-left text-sm font-semibold text-primary-500 cursor-pointer group"
                    onClick={() => handleSort('condition')}
                  >
                    <div className="flex items-center gap-2">
                      Condition
                      {getSortIcon('condition')}
                    </div>
                  </th>
                  <th className="px-6 py-4 text-right text-sm font-semibold text-primary-500">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-dark-200">
                {sortedParts.map(part => (
                  <tr key={part.id} className="group bg-dark-300 hover:bg-dark-200/70 transition-colors">
                    <td className="px-6 py-3">
                      <div className={getEditableFieldClass(!!editingStates[part.id]?.name)}>
                        <input
                          type="text"
                          value={editingStates[part.id]?.name ?? part.name}
                          onChange={(e) => handleFieldChange(part.id, 'name', e.target.value)}
                          onBlur={() => handleFieldBlur(part.id)}
                          onKeyDown={(e) => handleFieldKeyDown(e, part.id)}
                          className={getInputClass}
                        />
                        {!editingStates[part.id]?.name && (
                          <Pencil className="absolute right-2 top-1/2 -translate-y-1/2 w-3.5 h-3.5 text-gray-400 opacity-0 group-hover/field:opacity-100 group-focus-within/field:opacity-0 transition-opacity" />
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className={getEditableFieldClass(!!editingStates[part.id]?.manufacturer)}>
                        <input
                          type="text"
                          value={editingStates[part.id]?.manufacturer ?? part.manufacturer ?? ''}
                          onChange={(e) => handleFieldChange(part.id, 'manufacturer', e.target.value)}
                          onBlur={() => handleFieldBlur(part.id)}
                          onKeyDown={(e) => handleFieldKeyDown(e, part.id)}
                          className={getInputClass}
                        />
                        {!editingStates[part.id]?.manufacturer && (
                          <Pencil className="absolute right-2 top-1/2 -translate-y-1/2 w-3.5 h-3.5 text-gray-400 opacity-0 group-hover/field:opacity-100 group-focus-within/field:opacity-0 transition-opacity" />
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className={getEditableFieldClass(!!editingStates[part.id]?.category)}>
                        <select
                          value={editingStates[part.id]?.category ?? part.category}
                          onChange={(e) => {
                            handleFieldChange(part.id, 'category', e.target.value as PartCategory);
                            handleUpdatePart(part.id, { category: e.target.value as PartCategory });
                          }}
                          className={getSelectClass}
                        >
                          {PART_CATEGORIES.map(category => (
                            <option key={category} value={category}>{category}</option>
                          ))}
                        </select>
                        <ChevronDown className="absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400 pointer-events-none" />
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className={getEditableFieldClass(!!editingStates[part.id]?.subcategory)}>
                        <select
                          value={editingStates[part.id]?.subcategory ?? (part.subcategory || '')}
                          onChange={(e) => {
                            handleFieldChange(part.id, 'subcategory', e.target.value as PartSubcategory);
                            handleUpdatePart(part.id, { subcategory: e.target.value as PartSubcategory });
                          }}
                          className={getSelectClass}
                        >
                          <option value="">Select subcategory...</option>
                          {PART_SUBCATEGORIES[part.category].map(subcategory => (
                            <option key={subcategory} value={subcategory}>{subcategory}</option>
                          ))}
                        </select>
                        <ChevronDown className="absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400 pointer-events-none" />
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className={getEditableFieldClass(!!editingStates[part.id]?.cost)}>
                        <div className="relative">
                          <span className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400">$</span>
                          <input
                            type="number"
                            value={editingStates[part.id]?.cost ?? part.cost}
                            onChange={(e) => handleFieldChange(part.id, 'cost', Number(e.target.value))}
                            onBlur={() => handleFieldBlur(part.id)}
                            onKeyDown={(e) => handleFieldKeyDown(e, part.id)}
                            className={`${getInputClass} pl-6`}
                          />
                          {!editingStates[part.id]?.cost && (
                            <Pencil className="absolute right-2 top-1/2 -translate-y-1/2 w-3.5 h-3.5 text-gray-400 opacity-0 group-hover/field:opacity-100 group-focus-within/field:opacity-0 transition-opacity" />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className={getEditableFieldClass(!!editingStates[part.id]?.quantity)}>
                        <input
                          type="number"
                          value={editingStates[part.id]?.quantity ?? part.quantity}
                          min="0"
                          onChange={(e) => handleFieldChange(part.id, 'quantity', Number(e.target.value))}
                          onBlur={() => handleFieldBlur(part.id)}
                          onKeyDown={(e) => handleFieldKeyDown(e, part.id)}
                          className={getInputClass}
                        />
                        {!editingStates[part.id]?.quantity && (
                          <Pencil className="absolute right-2 top-1/2 -translate-y-1/2 w-3.5 h-3.5 text-gray-400 opacity-0 group-hover/field:opacity-100 group-focus-within/field:opacity-0 transition-opacity" />
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className={getEditableFieldClass(!!editingStates[part.id]?.condition)}>
                        <select
                          value={editingStates[part.id]?.condition ?? part.condition}
                          onChange={(e) => {
                            handleFieldChange(part.id, 'condition', e.target.value as typeof PART_CONDITIONS[number]);
                            handleUpdatePart(part.id, { condition: e.target.value as typeof PART_CONDITIONS[number] });
                          }}
                          className={getSelectClass}
                        >
                          {PART_CONDITIONS.map(condition => (
                            <option key={condition} value={condition}>{condition}</option>
                          ))}
                        </select>
                        <ChevronDown className="absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400 pointer-events-none" />
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className="flex items-center justify-end">
                        <button
                          onClick={() => handleDeletePart(part.id)}
                          className="p-1 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Mobile View */}
            <div className="lg:hidden space-y-2 p-4">
              <div className="bg-primary-500/10 rounded-lg px-4 py-3 mb-4">
                <h3 className="text-sm font-semibold text-primary-500">Parts List</h3>
              </div>
              {parts.map(part => (
                <div 
                  key={part.id}
                  className="bg-dark-200 rounded-lg overflow-hidden p-4 space-y-4 hover:bg-dark-200/70 transition-colors"
                >
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1.5">Name</label>
                      <input
                        type="text"
                        value={editingStates[part.id]?.name ?? part.name}
                        onChange={(e) => handleFieldChange(part.id, 'name', e.target.value)}
                        onBlur={() => handleFieldBlur(part.id)}
                        onKeyDown={(e) => handleFieldKeyDown(e, part.id)}
                        className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent transition-colors"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1.5">Manufacturer</label>
                      <input
                        type="text"
                        value={editingStates[part.id]?.manufacturer ?? part.manufacturer ?? ''}
                        onChange={(e) => handleFieldChange(part.id, 'manufacturer', e.target.value)}
                        onBlur={() => handleFieldBlur(part.id)}
                        onKeyDown={(e) => handleFieldKeyDown(e, part.id)}
                        className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent transition-colors"
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1.5">Category</label>
                        <select
                          value={editingStates[part.id]?.category ?? part.category}
                          onChange={(e) => handleFieldChange(part.id, 'category', e.target.value as PartCategory)}
                          onBlur={() => handleFieldBlur(part.id)}
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent transition-colors appearance-none"
                        >
                          {PART_CATEGORIES.map(category => (
                            <option key={category} value={category} className="bg-dark-200 text-white py-1 px-2">{category}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1.5">Subcategory</label>
                        <select
                          value={editingStates[part.id]?.subcategory ?? (part.subcategory || '')}
                          onChange={(e) => handleFieldChange(part.id, 'subcategory', e.target.value as PartSubcategory)}
                          onBlur={() => handleFieldBlur(part.id)}
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent transition-colors appearance-none"
                        >
                          <option value="">Select subcategory...</option>
                          {PART_SUBCATEGORIES[part.category].map(subcategory => (
                            <option key={subcategory} value={subcategory} className="bg-dark-200 text-white py-1 px-2">{subcategory}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1.5">Cost</label>
                        <div className="relative">
                          <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">$</span>
                          <input
                            type="number"
                            value={editingStates[part.id]?.cost ?? part.cost}
                            onChange={(e) => handleFieldChange(part.id, 'cost', Number(e.target.value))}
                            onBlur={() => handleFieldBlur(part.id)}
                            onKeyDown={(e) => handleFieldKeyDown(e, part.id)}
                            className="w-full bg-dark-300 border border-dark-50 rounded-md pl-6 pr-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent transition-colors"
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1.5">Quantity</label>
                        <input
                          type="number"
                          value={editingStates[part.id]?.quantity ?? part.quantity}
                          min="0"
                          onChange={(e) => handleFieldChange(part.id, 'quantity', Number(e.target.value))}
                          onBlur={() => handleFieldBlur(part.id)}
                          onKeyDown={(e) => handleFieldKeyDown(e, part.id)}
                          className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent transition-colors"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1.5">Condition</label>
                      <select
                        value={editingStates[part.id]?.condition ?? part.condition}
                        onChange={(e) => handleFieldChange(part.id, 'condition', e.target.value as typeof PART_CONDITIONS[number])}
                        onBlur={() => handleFieldBlur(part.id)}
                        className="w-full bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white focus:ring-1 focus:ring-primary-500 focus:border-transparent transition-colors appearance-none"
                      >
                        {PART_CONDITIONS.map(condition => (
                          <option key={condition} value={condition} className="bg-dark-200 text-white py-1 px-2">{condition}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
} 
import React from 'react';
import SetupInput from '../SetupInput';
import SetupInputGrid from '../SetupInputGrid';
import { SETUP_RANGES } from '../../../../constants/setup';
import type { VehicleSetup } from '../../../../types/vehicle';

interface DifferentialSetupProps {
  setup: VehicleSetup['differential'];
  onChange: (field: string, value: number) => void;
}

export default function DifferentialSetup({ setup, onChange }: DifferentialSetupProps) {
  const handleChange = (field: string, value: number | string) => {
    onChange(field, Number(value));
  };

  return (
    <SetupInputGrid spacing="wide">
      {setup.frontPreload !== undefined && (
        <SetupInput
          label="Front Preload"
          value={setup.frontPreload}
          onChange={(value) => handleChange('frontPreload', value)}
          type="slider"
          min={SETUP_RANGES.differential.preload.min}
          max={SETUP_RANGES.differential.preload.max}
          step={SETUP_RANGES.differential.preload.step}
          unit={SETUP_RANGES.differential.preload.unit}
          description="Initial torque required to create a speed difference between wheels"
        />
      )}
      {setup.rearPreload !== undefined && (
        <SetupInput
          label="Rear Preload"
          value={setup.rearPreload}
          onChange={(value) => handleChange('rearPreload', value)}
          type="slider"
          min={SETUP_RANGES.differential.preload.min}
          max={SETUP_RANGES.differential.preload.max}
          step={SETUP_RANGES.differential.preload.step}
          unit={SETUP_RANGES.differential.preload.unit}
          description="Initial torque required to create a speed difference between wheels"
        />
      )}
      {setup.centerPreload !== undefined && (
        <SetupInput
          label="Center Preload"
          value={setup.centerPreload}
          onChange={(value) => handleChange('centerPreload', value)}
          type="slider"
          min={SETUP_RANGES.differential.preload.min}
          max={SETUP_RANGES.differential.preload.max}
          step={SETUP_RANGES.differential.preload.step}
          unit={SETUP_RANGES.differential.preload.unit}
          description="Initial torque required to create a speed difference between axles"
        />
      )}
      {setup.frontRampAngle !== undefined && (
        <SetupInput
          label="Front Ramp Angle"
          value={setup.frontRampAngle}
          onChange={(value) => handleChange('frontRampAngle', value)}
          type="slider"
          min={SETUP_RANGES.differential.rampAngle.min}
          max={SETUP_RANGES.differential.rampAngle.max}
          step={SETUP_RANGES.differential.rampAngle.step}
          unit={SETUP_RANGES.differential.rampAngle.unit}
          description="Affects torque transfer under acceleration/deceleration"
        />
      )}
      {setup.rearRampAngle !== undefined && (
        <SetupInput
          label="Rear Ramp Angle"
          value={setup.rearRampAngle}
          onChange={(value) => handleChange('rearRampAngle', value)}
          type="slider"
          min={SETUP_RANGES.differential.rampAngle.min}
          max={SETUP_RANGES.differential.rampAngle.max}
          step={SETUP_RANGES.differential.rampAngle.step}
          unit={SETUP_RANGES.differential.rampAngle.unit}
          description="Affects torque transfer under acceleration/deceleration"
        />
      )}
      {setup.lockingPercentage !== undefined && (
        <SetupInput
          label="Locking Percentage"
          value={setup.lockingPercentage}
          onChange={(value) => handleChange('lockingPercentage', value)}
          type="slider"
          min={SETUP_RANGES.differential.lockingPercentage.min}
          max={SETUP_RANGES.differential.lockingPercentage.max}
          step={SETUP_RANGES.differential.lockingPercentage.step}
          unit={SETUP_RANGES.differential.lockingPercentage.unit}
          description="Maximum amount of torque transfer between wheels"
        />
      )}
    </SetupInputGrid>
  );
} 
import type { PartCategory, PartSubcategory, PartMetadata } from '../types/part';

export interface PartTemplate {
  name: string;
  category: PartCategory;
  subcategory?: PartSubcategory;
  description: string;
  metadata?: Partial<PartMetadata>;
  required?: Array<keyof PartMetadata>;
}

export const commonParts: PartTemplate[] = [
  {
    name: "Brake Pads",
    category: "Brakes",
    subcategory: "Pads",
    description: "Monitor pad wear and replacement intervals",
    metadata: {
      wearMetric: "thickness",
      changeInterval: 25000,
      wearLimit: 2.0 // mm
    },
    required: ["location", "currentWear"]
  },
  {
    name: "Tires",
    category: "Tires",
    subcategory: "Track",
    description: "Track tire wear and rotation schedule",
    metadata: {
      wearMetric: "treadDepth",
      changeInterval: 15000,
      wearLimit: 2/32 // inches
    },
    required: ["location", "currentWear"]
  },
  {
    name: "Engine Oil",
    category: "Engine",
    subcategory: "Oil",
    description: "Track oil changes and condition",
    metadata: {
      wearMetric: "miles",
      changeInterval: 5000
    },
    required: ["lastChanged"]
  },
  {
    name: "Brake Fluid",
    category: "Brakes",
    subcategory: "Fluid",
    description: "Monitor brake fluid condition and changes",
    metadata: {
      wearMetric: "months",
      changeInterval: 12
    },
    required: ["lastChanged"]
  },
  {
    name: "Air Filter",
    category: "Engine",
    subcategory: "Filter",
    description: "Track air filter condition",
    metadata: {
      wearMetric: "miles",
      changeInterval: 15000
    },
    required: ["lastChanged"]
  },
  {
    name: "Spark Plugs",
    category: "Engine",
    subcategory: "Spark Plugs",
    description: "Monitor spark plug wear and replacement",
    metadata: {
      wearMetric: "miles",
      changeInterval: 30000
    },
    required: ["lastChanged"]
  },
  {
    name: "Transmission Fluid",
    category: "Transmission",
    subcategory: "Fluid",
    description: "Track transmission fluid condition",
    metadata: {
      wearMetric: "miles",
      changeInterval: 30000
    },
    required: ["lastChanged"]
  },
  {
    name: "Differential Fluid",
    category: "Drivetrain",
    description: "Monitor differential fluid condition",
    metadata: {
      wearMetric: "miles",
      changeInterval: 30000
    },
    required: ["lastChanged", "location"]
  }
];

// Group parts by their primary function
export const partGroups = [
  {
    title: "Critical Wear Items",
    description: "Components that require regular monitoring",
    parts: ["Brake Pads", "Tires"]
  },
  {
    title: "Fluids & Filters",
    description: "Regular maintenance items",
    parts: ["Engine Oil", "Brake Fluid", "Air Filter", "Transmission Fluid", "Differential Fluid"]
  },
  {
    title: "Engine Components",
    description: "Key engine maintenance parts",
    parts: ["Spark Plugs"]
  }
]; 
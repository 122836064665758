import React from 'react';
import SetupConsumablesStatus from '../consumables/SetupConsumablesStatus';
import type { Vehicle } from '../../../types/vehicle';
import type { Part, PartMetadata, VehicleInstallations } from '../../../types/part';

interface SetupConsumablesProps {
  vehicle: Vehicle;
  parts: Part[];
  category: string;
  onInstallPart: (partId: string | null, slot: keyof VehicleInstallations, vehicleId: string) => Promise<void>;
  onUpdatePartMetadata: (partId: string, metadata: Partial<PartMetadata>) => Promise<void>;
  onUpdateVehicle: (updates: Partial<Vehicle>) => Promise<void>;
}

export default function SetupConsumables({ 
  vehicle, 
  parts,
  category,
  onInstallPart, 
  onUpdatePartMetadata,
  onUpdateVehicle 
}: SetupConsumablesProps) {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-medium text-white mb-4">Consumables Status</h3>
      <SetupConsumablesStatus
        vehicle={vehicle}
        parts={parts}
        setupCategory={category}
        onInstallPart={onInstallPart}
        onUpdatePartMetadata={onUpdatePartMetadata}
        onUpdateVehicle={onUpdateVehicle}
      />
    </div>
  );
} 
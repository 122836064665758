import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Calendar, MapPin, Car, Trash2 } from 'lucide-react';
import type { TrackEvent, EventType } from '../types/event';
import { useAuth } from '../contexts/AuthContext';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { toast } from 'react-hot-toast';

interface QuickEvent {
  type: EventType;
  title: string;
  location?: string;
  date?: Date;
}

export default function PlanSeason() {
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [events, setEvents] = useState<QuickEvent[]>([{
    type: 'race',
    title: ''
  }]);
  const [isCreating, setIsCreating] = useState(false);

  const handleAddEvent = () => {
    setEvents([...events, {
      type: 'race',
      title: ''
    }]);
  };

  const handleUpdateEvent = (index: number, updates: Partial<QuickEvent>) => {
    setEvents(events.map((event, i) => 
      i === index ? { ...event, ...updates } : event
    ));
  };

  const handleRemoveEvent = (index: number) => {
    setEvents(events.filter((_, i) => i !== index));
  };

  const handleCreateEvents = async () => {
    if (!programId || events.length === 0) return;
    setIsCreating(true);

    try {
      const eventsRef = collection(db, 'programs', programId, 'events');
      const createdEvents = [];

      for (const event of events) {
        if (!event.title.trim()) continue;

        const newEvent = {
          title: event.title,
          type: event.type,
          status: 'planned' as const,
          description: '',
          location: event.location,
          date: event.date,
          budget: {
            estimated: 0,
            expenses: [],
            currency: 'USD'
          },
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        const docRef = await addDoc(eventsRef, newEvent);
        createdEvents.push({ id: docRef.id, ...newEvent });
      }

      toast.success(`Added ${createdEvents.length} events to your season`);
      navigate('/app/schedule');
    } catch (error) {
      console.error('Error creating events:', error);
      toast.error('Failed to create events');
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <button
          onClick={() => navigate('/app/schedule')}
          className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
        >
          <ArrowLeft className="w-5 h-5" />
        </button>
        <h1 className="text-2xl font-bold text-white">Plan Your Season</h1>
      </div>

      <div className="bg-dark-200 rounded-lg p-6">
        <div className="space-y-6">
          {/* Introduction */}
          <div className="text-center max-w-2xl mx-auto">
            <p className="text-gray-400">
              Quickly add multiple events to your season. You can always update the details later.
            </p>
          </div>

          {/* Quick Add Events */}
          <div className="space-y-4">
            {events.map((event, index) => (
              <div key={index} className="bg-dark-100 rounded-lg p-4">
                <div className="flex gap-4">
                  <select
                    value={event.type}
                    onChange={(e) => handleUpdateEvent(index, { type: e.target.value as EventType })}
                    className="w-32 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                  >
                    <option value="race">Race</option>
                    <option value="practice">Practice</option>
                    <option value="maintenance">Testing</option>
                    <option value="other">Other</option>
                  </select>
                  <input
                    type="text"
                    value={event.title}
                    onChange={(e) => handleUpdateEvent(index, { title: e.target.value })}
                    placeholder="Event name or description"
                    className="flex-1 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white"
                  />
                  <button
                    onClick={() => handleRemoveEvent(index)}
                    className="p-2 text-gray-400 hover:text-red-500"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
                <div className="mt-3 flex gap-4">
                  <input
                    type="text"
                    value={event.location || ''}
                    onChange={(e) => handleUpdateEvent(index, { location: e.target.value })}
                    placeholder="Location (optional)"
                    className="flex-1 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white text-sm"
                  />
                  <input
                    type="date"
                    value={event.date ? new Date(event.date).toISOString().split('T')[0] : ''}
                    onChange={(e) => handleUpdateEvent(index, { 
                      date: e.target.value ? new Date(e.target.value) : undefined 
                    })}
                    className="w-40 bg-dark-300 border border-dark-50 rounded-md px-3 py-2 text-white text-sm"
                  />
                </div>
              </div>
            ))}

            <button
              onClick={handleAddEvent}
              className="w-full p-4 border-2 border-dashed border-dark-50 rounded-lg text-gray-400 hover:text-primary-500 hover:border-primary-500"
            >
              <Plus className="w-5 h-5 mx-auto" />
              <span className="block mt-1">Add Another Event</span>
            </button>
          </div>

          {/* Actions */}
          <div className="flex justify-end gap-4 pt-6 border-t border-dark-50">
            <button
              onClick={() => navigate('/app/schedule')}
              className="px-4 py-2 text-gray-400 hover:text-white"
              disabled={isCreating}
            >
              Cancel
            </button>
            <button
              onClick={handleCreateEvents}
              disabled={events.every(e => !e.title.trim()) || isCreating}
              className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:opacity-50"
            >
              {isCreating ? (
                <>
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  Adding Events...
                </>
              ) : (
                'Add Events'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 
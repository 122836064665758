import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { VehicleSetup, Vehicle } from '../types/vehicle';
import type { Part, PartMetadata, VehicleInstallations } from '../types/part';
import AlignmentSetup from '../components/vehicles/setup/categories/AlignmentSetup';
import SuspensionSetup from '../components/vehicles/setup/categories/SuspensionSetup';
import AeroSetup from '../components/vehicles/setup/categories/AeroSetup';
import BrakesSetup from '../components/vehicles/setup/categories/BrakesSetup';
import DifferentialSetup from '../components/vehicles/setup/categories/DifferentialSetup';
import GearingSetup from '../components/vehicles/setup/categories/GearingSetup';
import WeightSetup from '../components/vehicles/setup/categories/WeightSetup';
import TiresSetup from '../components/vehicles/setup/categories/TiresSetup';
import CoolingSetup from '../components/vehicles/setup/categories/CoolingSetup';
import EngineSetup from '../components/vehicles/setup/categories/EngineSetup';
import SafetySetup from '../components/vehicles/setup/categories/SafetySetup';
import SetupConsumables from '../components/vehicles/setup/SetupConsumables';

interface VehicleSetupDetailProps {
  setup?: VehicleSetup;
  onUpdateSetup: (setup: VehicleSetup) => void;
  vehicle: Vehicle;
  parts: Part[];
  onInstallPart: (partId: string | null, slot: keyof VehicleInstallations, vehicleId: string) => Promise<void>;
  onUpdatePartMetadata: (partId: string, metadata: Partial<PartMetadata>) => Promise<void>;
  onUpdateVehicle: (updates: Partial<Vehicle>) => Promise<void>;
}

type SetupValue = string | number | number[];

const CATEGORY_ICONS: Record<string, string> = {
  alignment: '/icons/Car Suspension.png',
  suspension: '/icons/Damper.png',
  aero: '/icons/Aero.png',
  brakes: '/icons/Disc Brake.png',
  differential: '/icons/Chassis.png',
  gearing: '/icons/Tachometer.png',
  weight: '/icons/Car Jack.png',
  tires: '/icons/Tire.png',
  fuel: '/icons/Gasoline.png',
  cooling: '/icons/Radiator.png',
  engine: '/icons/Car Engine.png'
};

// Map setup categories to their related consumable categories
const SETUP_TO_QUICK_ACCESS_MAP: Record<string, string[]> = {
  tires: ['tires'],
  brakes: ['brakes', 'brake-fluid'],
  engine: ['engine-oil', 'air-filter', 'coolant'],
  gearing: ['transmission-fluid'],
  cooling: ['coolant'],
  differential: ['power-steering']
};

export default function VehicleSetupDetail({ 
  setup, 
  onUpdateSetup,
  vehicle,
  parts,
  onInstallPart,
  onUpdatePartMetadata,
  onUpdateVehicle
}: VehicleSetupDetailProps) {
  const navigate = useNavigate();
  const { id, category } = useParams();
  const { programId } = useAuth();
  const [localSetup, setLocalSetup] = useState(setup);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [error, setError] = useState('');

  const createInitialSetup = (): VehicleSetup => ({
    alignment: {
      frontCamber: 0,
      rearCamber: 0,
      frontToe: 0,
      rearToe: 0,
      caster: 0
    },
    suspension: {
      frontRideHeight: 0,
      rearRideHeight: 0,
      frontSpringRate: 0,
      rearSpringRate: 0,
      frontRebound: 0,
      rearRebound: 0,
      frontCompression: 0,
      rearCompression: 0,
      frontBarSetting: 0,
      rearBarSetting: 0
    },
    aero: {
      frontDownforce: 0,
      rearDownforce: 0,
      wingAngle: 0,
      splitterHeight: 0,
      rideHeight: 0
    },
    tires: {
      compound: 300,
      frontPressureCold: 32,
      rearPressureCold: 32,
      frontPressureHot: 35,
      rearPressureHot: 35
    },
    brakes: {
      brakeBias: 50,
      frontPressure: 100,
      rearPressure: 100,
      padType: 'street'
    },
    differential: {
      frontPreload: 0,
      rearPreload: 0,
      centerPreload: 0,
      frontRampAngle: 0,
      rearRampAngle: 0,
      lockingPercentage: 50
    },
    gearing: {
      finalDrive: 3.55,
      gearRatios: [2.97, 2.07, 1.43, 1.00, 0.84, 0.56]
    },
    weight: {
      cornerWeights: {
        frontLeft: 0,
        frontRight: 0,
        rearLeft: 0,
        rearRight: 0
      },
      ballast: 0,
      ballastPosition: 'center',
      totalWeight: 0,
      distribution: {
        frontToRear: 50,
        leftToRight: 50
      }
    },
    cooling: {
      targetTemp: 190,
      fanOnTemp: 185,
      thermostatTemp: 180
    },
    engine: {
      idleRpm: 800,
      revLimit: 7000,
      fuelPressure: 43.5,
      boostPressure: 0
    },
    safety: {
      harnessExpiration: '',
      seatExpiration: '',
      helmetExpiration: '',
      glovesExpiration: '',
      suitExpiration: '',
      handsRestraintExpiration: '',
      neckRestraintExpiration: ''
    },
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });

  // Keep local setup in sync with prop
  useEffect(() => {
    setLocalSetup(setup);
  }, [setup]);

  useEffect(() => {
    async function fetchVehicle() {
      if (!programId || !id) return;

      try {
        const setupRef = doc(db, 'programs', programId, 'vehicles', id, 'setups', 'default');
        const setupSnap = await getDoc(setupRef);
        
        let setupData;
        if (!setupSnap.exists()) {
          // Create initial setup if it doesn't exist
          const initialSetup = createInitialSetup();
          await setDoc(setupRef, initialSetup);
          setupData = initialSetup;
        } else {
          setupData = setupSnap.data() as VehicleSetup;
          // Check if safety section exists, if not, initialize it
          if (!setupData.safety) {
            setupData = {
              ...setupData,
              safety: {
                harnessExpiration: '',
                seatExpiration: '',
                helmetExpiration: '',
                glovesExpiration: '',
                suitExpiration: '',
                handsRestraintExpiration: '',
                neckRestraintExpiration: ''
              }
            };
            // Update the document with new safety section
            await updateDoc(setupRef, setupData);
          }
        }
        
        setLocalSetup(setupData);
      } catch (err) {
        console.error('Error fetching setup:', err);
        setError('Failed to load setup');
      } finally {
        setIsInitialLoading(false);
      }
    }

    fetchVehicle();
  }, [programId, id]);

  const handleChange = async (section: keyof VehicleSetup, field: string, value: SetupValue, isTemporary: boolean = false) => {
    if (!localSetup || !programId || !id) return;
    
    const sectionData = localSetup[section] as Record<string, any>;
    const sectionUpdate = Object.assign({}, sectionData, {
      [field]: value
    });
    
    const newSetup = Object.assign({}, localSetup, {
      [section]: sectionUpdate,
      updatedAt: new Date().toISOString()
    });

    // Always update local state for smooth interaction
    setLocalSetup(newSetup);
    
    // Only update Firestore when the change is permanent (slider released)
    if (!isTemporary) {
      try {
        // Update the setup document
        const setupRef = doc(db, 'programs', programId, 'vehicles', id, 'setups', 'default');
        await updateDoc(setupRef, newSetup);
        
        // Update the vehicle's setup field
        onUpdateSetup(newSetup);
      } catch (error) {
        console.error('Error updating setup:', error);
        // Revert local state on error
        setLocalSetup(localSetup);
      }
    }
  };

  const renderSetupContent = () => {
    if (!localSetup || !category) return null;

    switch (category) {
      case 'alignment':
        return <AlignmentSetup setup={localSetup.alignment} onChange={(field: string, value: number) => handleChange('alignment', field, value)} />;
      case 'suspension':
        return <SuspensionSetup setup={localSetup.suspension} onChange={(field: string, value: number) => handleChange('suspension', field, value)} />;
      case 'aero':
        return <AeroSetup setup={localSetup.aero} onChange={(field: string, value: number) => handleChange('aero', field, value)} />;
      case 'brakes':
      case 'tires':
      case 'engine':
      case 'gearing':
      case 'cooling':
        return (
          <SetupConsumables
            vehicle={vehicle}
            parts={parts}
            category={category}
            onInstallPart={onInstallPart}
            onUpdatePartMetadata={onUpdatePartMetadata}
            onUpdateVehicle={onUpdateVehicle}
          />
        );
      case 'differential':
        return <DifferentialSetup setup={localSetup.differential} onChange={(field: string, value: number) => handleChange('differential', field, value)} />;
      case 'weight':
        return <WeightSetup setup={localSetup.weight} onChange={(field: string, value: number) => handleChange('weight', field, value)} />;
      case 'safety':
        return <SafetySetup setup={localSetup.safety} onChange={(field: string, value: string) => handleChange('safety', field, value)} />;
      default:
        return null;
    }
  };

  const getCategoryTitle = () => {
    return category ? category.charAt(0).toUpperCase() + category.slice(1) : '';
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-4">
        <button
          onClick={() => navigate(`/app/vehicle/${id}`)}
          className="p-2 hover:bg-dark-100 rounded-lg transition-colors"
        >
          <ArrowLeft className="w-6 h-6 text-gray-400" />
        </button>
        {category && CATEGORY_ICONS[category] && (
          <div className="h-16 w-16 flex-shrink-0 -m-2">
            <img 
              src={CATEGORY_ICONS[category]} 
              alt={category} 
              className="h-full w-full object-contain"
            />
          </div>
        )}
        <h2 className="text-2xl font-semibold text-white">
          {getCategoryTitle()} Setup
        </h2>
      </div>

      <div className="bg-dark-300 rounded-lg p-6">
        {renderSetupContent()}
      </div>
    </div>
  );
} 
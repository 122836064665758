import { Toaster as HotToaster } from 'react-hot-toast';

export function Toaster() {
  return (
    <HotToaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerStyle={{}}
      containerClassName=""
      toastOptions={{
        className: 'bg-dark-200 text-white border border-dark-100',
        duration: 1500,
        style: {
          background: '#1a1b1e',
          color: '#fff',
          border: '1px solid #2a2b2e',
        },
        success: {
          iconTheme: {
            primary: '#10b981',
            secondary: '#fff',
          },
        },
        error: {
          iconTheme: {
            primary: '#ef4444',
            secondary: '#fff',
          },
        },
      }}
    />
  );
} 
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Navigate } from 'react-router-dom';
import { Loader2, Car } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import VehicleForm from '../components/vehicles/VehicleForm';
import type { Vehicle } from '../types/vehicle';

interface GarageProps {
  vehicles: Vehicle[];
  setVehicles: Dispatch<SetStateAction<Vehicle[]>>;
}

export default function Garage({ vehicles, setVehicles }: GarageProps) {
  const { programId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  // If a vehicle exists, redirect to its detail page
  if (vehicles.length > 0) {
    return <Navigate to={`/app/vehicle/${vehicles[0].id}`} replace />;
  }

  return (
    <div className="space-y-8">
      <div className="text-center">
        <div className="flex justify-center mb-6">
          <div className="w-16 h-16 rounded-full bg-primary-500/10 flex items-center justify-center">
            <Car className="w-8 h-8 text-primary-500" />
          </div>
        </div>
        <h1 className="text-3xl font-bold text-white mb-4">Welcome to PitPrep</h1>
        <p className="text-gray-400 max-w-lg mx-auto mb-8">
          Get started by adding your vehicle. PitPrep will help you track maintenance,
          schedule events, and keep your car in top condition.
        </p>
      </div>

      <div className="max-w-2xl mx-auto bg-dark-200 rounded-lg p-6">
        <VehicleForm vehicles={vehicles} setVehicles={setVehicles} />
      </div>
    </div>
  );
}
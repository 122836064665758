import React, { useCallback, useMemo } from 'react';
import { Gauge, Disc, Droplet, Plus, AlertCircle, Calendar, Clock, ChevronDown } from 'lucide-react';
import type { Part, PartMetadata } from '../../../types/part';
import type { Vehicle } from '../../../types/vehicle';
import type { LucideIcon } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';

type VehicleInstallations = NonNullable<Vehicle['installations']>;

interface SetupConsumablesStatusProps {
  parts: Part[];
  vehicle: Vehicle;
  setupCategory: string;
  onInstallPart: (partId: string | null, slot: keyof VehicleInstallations, vehicleId: string) => Promise<void>;
  onUpdatePartMetadata: (partId: string, metadata: Partial<PartMetadata>) => Promise<void>;
  onUpdateVehicle: (updates: Partial<Vehicle>) => Promise<void>;
}

type QuickAccessCategory = {
  id: string;
  name: string;
  icon: LucideIcon;
  category: string;
  subcategories?: readonly string[];
  hasFrontRear?: boolean;
  description: string;
  frontSlot?: keyof VehicleInstallations;
  rearSlot?: keyof VehicleInstallations;
  singleSlot?: keyof VehicleInstallations;
  wearMetric?: PartMetadata['wearMetric'];
  defaultChangeInterval?: number; // in months
};

const QUICK_ACCESS_CATEGORIES: QuickAccessCategory[] = [
  {
    id: 'tires',
    name: 'Tires',
    icon: Gauge,
    category: 'Tires',
    subcategories: ['Summer', 'Winter', 'Track', 'Rain'],
    hasFrontRear: true,
    description: 'Tire setup',
    frontSlot: 'frontTires',
    rearSlot: 'rearTires',
    wearMetric: 'treadDepth'
  },
  {
    id: 'brakes',
    name: 'Brake Pads',
    icon: Disc,
    category: 'Brakes',
    subcategories: ['Pads'],
    hasFrontRear: true,
    description: 'Brake pads',
    frontSlot: 'frontBrakePads',
    rearSlot: 'rearBrakePads',
    wearMetric: 'thickness'
  },
  {
    id: 'brake-fluid',
    name: 'Brake Fluid',
    icon: Droplet,
    category: 'Brakes',
    subcategories: ['Fluid'],
    description: 'Brake fluid',
    singleSlot: 'brakeFluid',
    wearMetric: 'months',
    defaultChangeInterval: 12
  },
  {
    id: 'engine-oil',
    name: 'Engine Oil',
    icon: Droplet,
    category: 'Engine',
    subcategories: ['Oil'],
    description: 'Engine oil',
    singleSlot: 'engineOil',
    wearMetric: 'months',
    defaultChangeInterval: 6
  },
  {
    id: 'air-filter',
    name: 'Air Filter',
    icon: Gauge,
    category: 'Engine',
    subcategories: ['Intake'],
    description: 'Engine air filter',
    singleSlot: 'airFilter',
    wearMetric: 'months',
    defaultChangeInterval: 12
  },
  {
    id: 'coolant',
    name: 'Coolant',
    icon: Droplet,
    category: 'Engine',
    subcategories: ['Coolant'],
    description: 'Engine coolant',
    singleSlot: 'coolant',
    wearMetric: 'months',
    defaultChangeInterval: 24
  },
  {
    id: 'transmission-fluid',
    name: 'Transmission Fluid',
    icon: Droplet,
    category: 'Transmission',
    subcategories: ['Fluid'],
    description: 'Transmission fluid',
    singleSlot: 'transmissionFluid',
    wearMetric: 'months',
    defaultChangeInterval: 24
  },
  {
    id: 'power-steering',
    name: 'Power Steering Fluid',
    icon: Droplet,
    category: 'Steering',
    subcategories: ['Fluid'],
    description: 'Power steering fluid',
    singleSlot: 'powerSteeringFluid',
    wearMetric: 'months',
    defaultChangeInterval: 24
  }
];

// Map setup categories to their related consumable categories
const SETUP_TO_QUICK_ACCESS_MAP: Record<string, string[]> = {
  tires: ['tires'],
  brakes: ['brakes', 'brake-fluid'],
  engine: ['engine-oil', 'air-filter'],
  gearing: ['transmission-fluid'],
  cooling: ['coolant'],
  differential: ['power-steering'],
};

const SetupConsumablesStatus = React.memo(({ 
  parts, 
  vehicle, 
  setupCategory,
  onInstallPart, 
  onUpdatePartMetadata,
  onUpdateVehicle 
}: SetupConsumablesStatusProps) => {
  const [draggingValue, setDraggingValue] = React.useState<{slot: keyof VehicleInstallations, value: number} | null>(null);
  // Add local state for installations with a default empty object
  const [localInstallations, setLocalInstallations] = React.useState<NonNullable<Vehicle['installations']>>({});

  // Sync local state with props when vehicle changes
  React.useEffect(() => {
    setLocalInstallations(vehicle.installations || {});
  }, [vehicle.installations]);

  // Get only the categories relevant to this setup section
  const relevantQuickAccessIds = SETUP_TO_QUICK_ACCESS_MAP[setupCategory] || [];
  const relevantCategories = QUICK_ACCESS_CATEGORIES.filter(cat => 
    relevantQuickAccessIds.includes(cat.id)
  );

  const getWearStatus = (slot: keyof VehicleInstallations) => {
    const lifeKey = `${slot}Life` as keyof Vehicle['consumables'];
    const remainingLife = draggingValue?.slot === slot 
      ? draggingValue.value
      : vehicle.consumables?.[lifeKey] ?? 100;
    
    return {
      percentage: remainingLife,
      color: remainingLife <= 20 ? 'text-red-500' : 
             remainingLife <= 50 ? 'text-yellow-500' : 
             'text-green-500',
      bgColor: remainingLife <= 20 ? 'bg-red-500' : 
               remainingLife <= 50 ? 'bg-yellow-500' : 
               'bg-green-500'
    };
  };

  const getMaintenanceStatus = (part: Part | undefined, category: QuickAccessCategory) => {
    if (!part?.metadata?.lastChanged) return null;

    const lastChanged = new Date(part.metadata.lastChanged);
    const nextChange = part.metadata.nextChangeDate 
      ? new Date(part.metadata.nextChangeDate)
      : new Date(lastChanged.getTime() + (category.defaultChangeInterval || 6) * 30 * 24 * 60 * 60 * 1000);

    const now = new Date();
    const totalInterval = nextChange.getTime() - lastChanged.getTime();
    const elapsed = now.getTime() - lastChanged.getTime();
    const remainingLife = 100 - ((elapsed / totalInterval) * 100);

    return {
      lastChanged,
      nextChange,
      percentage: remainingLife,
      color: remainingLife <= 20 ? 'text-red-500' : 
             remainingLife <= 50 ? 'text-yellow-500' : 
             'text-green-500',
      bgColor: remainingLife <= 20 ? 'bg-red-500' : 
               remainingLife <= 50 ? 'bg-yellow-500' : 
               'bg-green-500'
    };
  };

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    }).format(date);
  };

  const handleDateUpdate = async (part: Part, category: QuickAccessCategory, date: string) => {
    const lastChanged = new Date(date);
    const nextChange = new Date(lastChanged.getTime() + (category.defaultChangeInterval || 6) * 30 * 24 * 60 * 60 * 1000);
    
    try {
      await onUpdatePartMetadata(part.id, {
        lastChanged: lastChanged.toISOString(),
        nextChangeDate: nextChange.toISOString()
      });

      const today = new Date();
      const isToday = today.toDateString() === lastChanged.toDateString();
      
      if (isToday) {
        toast.success(`Marked ${part.name} as changed today`);
      } else {
        toast.success(`Updated ${part.name} change date to ${formatDate(lastChanged)}`);
      }
    } catch (error) {
      console.error('Error updating maintenance dates:', error);
      toast.error('Failed to update maintenance date');
    }
  };

  const getMatchingParts = (category: string, subcategories?: readonly string[]) => {
    return parts.filter(p => {
      const categoryMatch = p.category === category;
      const subcategoryMatch = !subcategories || subcategories.includes(p.subcategory || '');
      return categoryMatch && subcategoryMatch;
    });
  };

  const renderMaintenanceInfo = (part: Part | undefined, category: QuickAccessCategory) => {
    const status = getMaintenanceStatus(part, category);
    if (!status) return null;
    return (
      <div className="mt-3 space-y-2">
        <div className="flex items-center justify-between text-xs">
          <div className="flex items-center gap-1.5 text-gray-400">
            <Calendar className="w-3.5 h-3.5" />
            <span>Last changed: {formatDate(status.lastChanged)}</span>
          </div>
          <div className="flex items-center gap-1.5 text-gray-400">
            <Clock className="w-3.5 h-3.5" />
            <span>Next: {formatDate(status.nextChange)}</span>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex-1 h-1.5 bg-dark-300 rounded-full overflow-hidden">
            <div 
              className={`h-full rounded-full ${status.bgColor} transition-all duration-300 ease-in-out`}
              style={{ width: `${Math.min(status.percentage, 100)}%` }}
            />
          </div>
          <span className={`text-xs font-medium ${status.color}`}>
            {Math.round(status.percentage)}%
          </span>
        </div>
      </div>
    );
  };

  const renderInstalledPart = (
    installedPartId: string | undefined,
    matchingParts: Part[],
    slot: keyof VehicleInstallations,
    category: QuickAccessCategory
  ) => {
    const installedPart = useMemo(() => 
      matchingParts.find(p => p.id === localInstallations[slot]) || null,
      [matchingParts, localInstallations, slot]
    );
    
    const wearStatus = useMemo(() => 
      installedPart ? getWearStatus(slot) : null,
      [installedPart, slot]
    );

    return (
      <div className="relative">
        <div className="relative">
          <select
            value={localInstallations[slot] || ""}
            onChange={(e) => {
              const newValue = e.target.value;
              // Update local state immediately
              setLocalInstallations(prev => ({
                ...prev,
                [slot]: newValue || null
              }));
              // Then sync with backend
              onInstallPart(newValue || null, slot, vehicle.id).catch(error => {
                console.error('Error installing part:', error);
                // Revert local state on error
                setLocalInstallations(prev => ({
                  ...prev,
                  [slot]: installedPartId
                }));
              });
            }}
            className="w-full bg-dark-100 text-white border border-dark-300 rounded px-3 py-2 text-sm focus:outline-none focus:border-primary-500 appearance-none hover:bg-dark-200/50 transition-colors pr-8"
          >
            <option value="" className="text-gray-400 bg-dark-100">Not installed</option>
            {matchingParts.map(p => (
              <option key={p.id} value={p.id} className="text-white bg-dark-100">
                {p.name} - {p.manufacturer}
              </option>
            ))}
          </select>

          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <div className={`w-2 h-2 rounded-full transition-colors duration-150 ${
              !installedPart ? 'opacity-0' :
              !wearStatus ? 'bg-green-500' :
              draggingValue?.slot === slot
                ? draggingValue.value <= 20 ? 'bg-red-500'
                  : draggingValue.value <= 50 ? 'bg-yellow-500'
                  : 'bg-green-500'
                : wearStatus.percentage <= 20 ? 'bg-red-500'
                  : wearStatus.percentage <= 50 ? 'bg-yellow-500'
                  : 'bg-green-500'
            }`} />
            <Plus className={`h-4 w-4 text-gray-400 absolute transition-opacity duration-150 ${installedPart ? 'opacity-0' : 'opacity-100'}`} />
          </div>
          <ChevronDown className="w-4 h-4 text-gray-400 absolute right-8 top-1/2 -translate-y-1/2 pointer-events-none" />
        </div>

        <div className={`mt-3 space-y-3 transition-all duration-150 ${installedPart ? 'opacity-100' : 'opacity-0 pointer-events-none h-0 overflow-hidden'}`}>
          {installedPart && category.wearMetric === 'treadDepth' && (
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Tire Life</span>
                {wearStatus && (
                  <span className={`text-sm font-medium ${
                    draggingValue?.slot === slot
                      ? draggingValue.value >= 90 ? 'text-green-500'
                        : draggingValue.value >= 66 ? 'text-green-500'
                        : draggingValue.value >= 33 ? 'text-yellow-500'
                        : 'text-red-500'
                      : wearStatus.color
                  }`}>
                    {draggingValue?.slot === slot
                      ? draggingValue.value >= 90 ? 'New'
                        : draggingValue.value >= 66 ? 'Light Use'
                        : draggingValue.value >= 33 ? 'Moderate'
                        : 'Replace Soon'
                      : wearStatus.percentage >= 90 ? 'New'
                        : wearStatus.percentage >= 66 ? 'Light Use'
                        : wearStatus.percentage >= 33 ? 'Moderate'
                        : 'Replace Soon'}
                  </span>
                )}
              </div>
              <div 
                className="flex items-center gap-2 group relative"
                title="Drag to adjust remaining life"
              >
                <div className="flex-1 relative">
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={draggingValue?.slot === slot
                      ? draggingValue.value 
                      : Math.round(wearStatus?.percentage || 0)}
                    onChange={(e) => {
                      const value = parseInt((e.target as HTMLInputElement).value);
                      setDraggingValue({ 
                        slot, 
                        value: Math.max(0, Math.min(value, 100))
                      });
                    }}
                    onMouseUp={(e) => {
                      if (!draggingValue) return;
                      handleWearUpdate(slot, draggingValue.value);
                    }}
                    onTouchEnd={(e) => {
                      if (!draggingValue) return;
                      handleWearUpdate(slot, draggingValue.value);
                    }}
                    className="absolute inset-0 w-full opacity-0 cursor-pointer z-10 md:h-2.5 h-5"
                  />
                  <div className="h-2.5 md:h-2.5 h-5 bg-dark-300 rounded-full overflow-hidden">
                    {wearStatus && (
                      <div 
                        className={`h-full rounded-full ${
                          draggingValue?.slot === slot
                            ? draggingValue.value >= 66 ? 'bg-green-500'
                              : draggingValue.value >= 33 ? 'bg-yellow-500'
                              : 'bg-red-500'
                            : wearStatus.bgColor
                        } ${
                          draggingValue?.slot === slot
                            ? '' // No transition during drag
                            : 'transition-all duration-100 ease-in-out'
                        } group-hover:opacity-80`}
                        style={{ width: `${draggingValue?.slot === slot
                          ? draggingValue.value 
                          : Math.min(wearStatus.percentage || 0, 100)}%` }}
                      />
                    )}
                  </div>
                </div>
                {wearStatus && (
                  <span className={`text-xs font-medium ${
                    draggingValue?.slot === slot
                      ? draggingValue.value <= 20 ? 'text-red-500'
                        : draggingValue.value <= 50 ? 'text-yellow-500'
                        : 'text-green-500'
                      : wearStatus.percentage <= 20 ? 'text-red-500'
                        : wearStatus.percentage <= 50 ? 'text-yellow-500'
                        : 'text-green-500'
                  }`}>
                    {draggingValue?.slot === slot
                      ? draggingValue.value 
                      : Math.round(wearStatus.percentage || 0)}%
                  </span>
                )}
              </div>
            </div>
          )}

          {installedPart && category.wearMetric === 'thickness' && (
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Pad Life</span>
                {wearStatus && (
                  <span className={`text-sm font-medium ${
                    draggingValue?.slot === slot
                      ? draggingValue.value >= 90 ? 'text-green-500'
                        : draggingValue.value >= 66 ? 'text-green-500'
                        : draggingValue.value >= 33 ? 'text-yellow-500'
                        : 'text-red-500'
                      : wearStatus.color
                  }`}>
                    {draggingValue?.slot === slot
                      ? draggingValue.value >= 90 ? 'New'
                        : draggingValue.value >= 66 ? 'Good'
                        : draggingValue.value >= 33 ? 'Fair'
                        : 'Replace Soon'
                      : wearStatus.percentage >= 90 ? 'New'
                        : wearStatus.percentage >= 66 ? 'Good'
                        : wearStatus.percentage >= 33 ? 'Fair'
                        : 'Replace Soon'}
                  </span>
                )}
              </div>
              <div 
                className="flex items-center gap-2 group relative"
                title="Drag to adjust remaining life"
              >
                <div className="flex-1 relative">
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={draggingValue?.slot === slot
                      ? draggingValue.value 
                      : Math.round(wearStatus?.percentage || 0)}
                    onChange={(e) => {
                      const value = parseInt((e.target as HTMLInputElement).value);
                      setDraggingValue({ 
                        slot, 
                        value: Math.max(0, Math.min(value, 100))
                      });
                    }}
                    onMouseUp={(e) => {
                      if (!draggingValue) return;
                      handleWearUpdate(slot, draggingValue.value);
                    }}
                    onTouchEnd={(e) => {
                      if (!draggingValue) return;
                      handleWearUpdate(slot, draggingValue.value);
                    }}
                    className="absolute inset-0 w-full opacity-0 cursor-pointer z-10 md:h-2.5 h-5"
                  />
                  <div className="h-2.5 md:h-2.5 h-5 bg-dark-300 rounded-full overflow-hidden">
                    {wearStatus && (
                      <div 
                        className={`h-full rounded-full ${
                          draggingValue?.slot === slot
                            ? draggingValue.value >= 66 ? 'bg-green-500'
                              : draggingValue.value >= 33 ? 'bg-yellow-500'
                              : 'bg-red-500'
                            : wearStatus.bgColor
                        } ${
                          draggingValue?.slot === slot
                            ? '' // No transition during drag
                            : 'transition-all duration-100 ease-in-out'
                        } group-hover:opacity-80`}
                        style={{ width: `${draggingValue?.slot === slot
                          ? draggingValue.value 
                          : Math.min(wearStatus.percentage || 0, 100)}%` }}
                      />
                    )}
                  </div>
                </div>
                {wearStatus && (
                  <span className={`text-xs font-medium ${
                    draggingValue?.slot === slot
                      ? draggingValue.value <= 20 ? 'text-red-500'
                        : draggingValue.value <= 50 ? 'text-yellow-500'
                        : 'text-green-500'
                      : wearStatus.percentage <= 20 ? 'text-red-500'
                        : wearStatus.percentage <= 50 ? 'text-yellow-500'
                        : 'text-green-500'
                  }`}>
                    {draggingValue?.slot === slot
                      ? draggingValue.value 
                      : Math.round(wearStatus.percentage || 0)}%
                  </span>
                )}
              </div>
            </div>
          )}

          {installedPart && category.wearMetric === 'months' && (
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-1.5 text-gray-400">
                  <Calendar className="w-3.5 h-3.5" />
                  <span className="text-sm">
                    {installedPart.metadata?.lastChanged 
                      ? formatDate(new Date(installedPart.metadata.lastChanged))
                      : 'Not recorded'}
                  </span>
                </div>
                <button
                  onClick={() => handleDateUpdate(installedPart, category, new Date().toISOString())}
                  className="text-sm text-primary-500 hover:text-primary-400"
                >
                  Mark Changed Today
                </button>
              </div>

              {installedPart.metadata?.lastChanged && (
                <>
                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center gap-1.5 text-gray-400">
                      <Clock className="w-3.5 h-3.5" />
                      <span>Change due {formatDate(new Date(installedPart.metadata.nextChangeDate || ''))}</span>
                    </div>
                  </div>
                  <div 
                    className="flex items-center gap-2 group relative"
                    title="Drag to adjust remaining life"
                  >
                    <div className="flex-1 relative">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={draggingValue?.slot === slot
                          ? draggingValue.value 
                          : Math.round(wearStatus?.percentage || 0)}
                        onChange={(e) => {
                          const value = parseInt((e.target as HTMLInputElement).value);
                          setDraggingValue({ 
                            slot, 
                            value: Math.max(0, Math.min(value, 100))
                          });
                        }}
                        onMouseUp={(e) => {
                          if (!draggingValue) return;
                          handleWearUpdate(slot, draggingValue.value);
                        }}
                        onTouchEnd={(e) => {
                          if (!draggingValue) return;
                          handleWearUpdate(slot, draggingValue.value);
                        }}
                        className="absolute inset-0 w-full opacity-0 cursor-pointer z-10 md:h-2.5 h-5"
                      />
                      <div className="h-2.5 md:h-2.5 h-5 bg-dark-300 rounded-full overflow-hidden">
                        {wearStatus && (
                          <div 
                            className={`h-full rounded-full ${
                              draggingValue?.slot === slot
                                ? draggingValue.value >= 66 ? 'bg-green-500'
                                  : draggingValue.value >= 33 ? 'bg-yellow-500'
                                  : 'bg-red-500'
                                : wearStatus.bgColor
                            } ${
                              draggingValue?.slot === slot
                                ? '' // No transition during drag
                                : 'transition-all duration-100 ease-in-out'
                            } group-hover:opacity-80`}
                            style={{ 
                              width: `${draggingValue?.slot === slot
                                ? draggingValue.value 
                                : Math.min(wearStatus.percentage || 0, 100)}%` 
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {wearStatus && (
                      <span className={`text-xs font-medium ${
                        draggingValue?.slot === slot
                          ? draggingValue.value <= 20 ? 'text-red-500'
                            : draggingValue.value <= 50 ? 'text-yellow-500'
                            : 'text-green-500'
                          : wearStatus.percentage <= 20 ? 'text-red-500'
                            : wearStatus.percentage <= 50 ? 'text-yellow-500'
                            : 'text-green-500'
                      }`}>
                        {draggingValue?.slot === slot
                          ? draggingValue.value 
                          : Math.round(wearStatus.percentage || 0)}%
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleWearUpdate = (slot: keyof VehicleInstallations, remainingLife: number) => {
    try {
      const lifeKey = `${slot}Life` as keyof Vehicle['consumables'];
      const changedKey = `${slot}Changed` as keyof Vehicle['consumables'];
      
      // Update local state first
      setDraggingValue({ slot, value: remainingLife });
      
      // Create the update object
      const updates = {
        consumables: {
          ...vehicle.consumables,
          [lifeKey]: remainingLife,
          [changedKey]: remainingLife === 100 ? new Date().toISOString() : vehicle.consumables?.[changedKey]
        }
      };

      // Update the vehicle
      onUpdateVehicle(updates);
      
      const condition = remainingLife >= 90 ? 'New' :
                       remainingLife >= 66 ? 'Light Use' :
                       remainingLife >= 33 ? 'Fair' :
                       'Replace Soon';
      
      const partName = parts.find(p => p.id === vehicle.installations?.[slot])?.name || slot;
      toast.success(`Updated ${partName} condition to ${condition}`);
    } catch (error) {
      toast.error('Failed to update wear status');
    }
  };

  const handleLifePercentageUpdate = async (part: Part, category: QuickAccessCategory, percentage: number) => {
    try {
      if (category.wearMetric === 'months') {
        const now = new Date();
        const totalInterval = (category.defaultChangeInterval || 6) * 30 * 24 * 60 * 60 * 1000;
        const elapsed = totalInterval * ((100 - percentage) / 100);
        const lastChanged = new Date(now.getTime() - elapsed);
        const nextChange = new Date(lastChanged.getTime() + totalInterval);

        onUpdatePartMetadata(part.id, {
          lastChanged: lastChanged.toISOString(),
          nextChangeDate: nextChange.toISOString()
        });
        
        toast.success(`Updated ${part.name} condition`);
      } else {
        const slot = category.frontSlot || category.rearSlot;
        if (!slot) return;

        const lifeKey = `${slot}Life` as keyof Vehicle['consumables'];
        const changedKey = `${slot}Changed` as keyof Vehicle['consumables'];
        
        onUpdateVehicle({
          consumables: {
            ...vehicle.consumables,
            [lifeKey]: percentage,
            [changedKey]: percentage === 100 ? new Date().toISOString() : vehicle.consumables?.[changedKey]
          }
        });
      }
      
      toast.success(`Updated ${part.name} condition`);
    } catch (error) {
      toast.error('Failed to update condition');
    }
  };

  const handleBarDrag = useCallback((e: React.MouseEvent | MouseEvent, barElement: HTMLDivElement, part: Part, category: QuickAccessCategory) => {
    const rect = barElement.getBoundingClientRect();
    let currentPercentage = 0;

    const updatePercentage = (clientX: number) => {
      const percentage = Math.round(((clientX - rect.left) / rect.width) * 100);
      currentPercentage = Math.max(0, Math.min(percentage, 100));
      // Update the visual bar without saving
      const statusBar = barElement.querySelector('.rounded-full') as HTMLDivElement;
      if (statusBar) {
        statusBar.style.width = `${currentPercentage}%`;
      }
      // Update the percentage text
      const percentageText = barElement.parentElement?.querySelector('span');
      if (percentageText) {
        percentageText.textContent = `${currentPercentage}%`;
      }
    };

    updatePercentage(e.clientX);

    const handleMouseMove = (e: MouseEvent) => {
      updatePercentage(e.clientX);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      // Only update the database and show toast when the drag is complete
      handleLifePercentageUpdate(part, category, currentPercentage);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, []);

  return (
    <div className="space-y-4">
      {relevantCategories.map(category => {
        const matchingParts = getMatchingParts(category.category, category.subcategories);
        const installedParts = category.hasFrontRear
          ? {
              front: vehicle.installations?.[category.frontSlot!],
              rear: vehicle.installations?.[category.rearSlot!]
            }
          : category.singleSlot
            ? { single: vehicle.installations?.[category.singleSlot] }
            : null;

        return (
          <div key={category.id} className="bg-gray-800 rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <category.icon className="w-5 h-5 text-gray-400" />
                <h4 className="text-white font-medium">{category.name}</h4>
              </div>
            </div>

            {category.hasFrontRear ? (
              <div className="space-y-6">
                <div>
                  <div className="flex items-center justify-between text-sm text-gray-400 mb-2">
                    <span>Front</span>
                  </div>
                  {renderInstalledPart(installedParts?.front, matchingParts, category.frontSlot!, category)}
                </div>

                <div>
                  <div className="flex items-center justify-between text-sm text-gray-400 mb-2">
                    <span>Rear</span>
                  </div>
                  {renderInstalledPart(installedParts?.rear, matchingParts, category.rearSlot!, category)}
                </div>
              </div>
            ) : category.singleSlot ? (
              <div>
                {renderInstalledPart(installedParts?.single, matchingParts, category.singleSlot, category)}
              </div>
            ) : null}

            {/* Maintenance Status */}
            {(category.hasFrontRear || category.singleSlot) && (
              <div className="mt-4 text-sm">
                {category.wearMetric === 'months' && (
                  <div className="flex items-center gap-2 text-gray-400">
                    <Clock className="w-4 h-4" />
                    <span>
                      {category.defaultChangeInterval 
                        ? `Change every ${category.defaultChangeInterval} months`
                        : 'No change interval set'}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
});

SetupConsumablesStatus.displayName = 'SetupConsumablesStatus';

export default SetupConsumablesStatus; 
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Home, ArrowLeft } from 'lucide-react';

interface ErrorPageProps {
  type: '404' | 'unauthorized';
}

export default function ErrorPage({ type }: ErrorPageProps) {
  const navigate = useNavigate();

  const content = {
    '404': {
      title: 'Page Not Found',
      description: 'Sorry, we couldn\'t find the page you\'re looking for.',
      image: '🔍'
    },
    'unauthorized': {
      title: 'Access Denied',
      description: 'Sorry, you don\'t have permission to access this page.',
      image: '🔒'
    }
  };

  return (
    <div className="min-h-screen bg-dark-300 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 text-center">
        <div>
          <div className="text-6xl mb-4">{content[type].image}</div>
          <h2 className="text-2xl font-bold text-white mb-2">
            {content[type].title}
          </h2>
          <p className="text-gray-400 mb-8">
            {content[type].description}
          </p>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-dark-100 hover:bg-dark-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Go Back
          </button>
          <button
            onClick={() => navigate('/app')}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <Home className="w-4 h-4 mr-2" />
            Go Home
          </button>
        </div>
      </div>
    </div>
  );
} 
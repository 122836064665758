import React from 'react';

interface SetupInputGridProps {
  children: React.ReactNode;
  spacing?: 'normal' | 'wide';
}

export default function SetupInputGrid({ children, spacing = 'normal' }: SetupInputGridProps) {
  return (
    <div className={`grid grid-cols-1 ${spacing === 'wide' ? 'gap-6' : 'gap-4'}`}>
      {children}
    </div>
  );
} 
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Calendar, Car, Wrench, Clock, Plus, ChevronRight, 
  AlertTriangle, CheckCircle2, Flag, Settings, 
  ArrowUpRight, DollarSign
} from 'lucide-react';
import { format } from 'date-fns';
import StatCard from '../components/dashboard/StatCard';
import { useAuth } from '../contexts/AuthContext';
import type { Vehicle } from '../types/vehicle';
import type { Task } from '../types/task';
import type { TrackEvent } from '../types/event';
import type { Budget } from '../types/budget';

interface DashboardProps {
  events: TrackEvent[];
  vehicles: Vehicle[];
  tasks: Task[];
  budget: Budget | null;
}

export default function Dashboard({ events, vehicles, tasks, budget }: DashboardProps) {
  const navigate = useNavigate();
  const { user } = useAuth();

  // Get upcoming events (next 30 days)
  const upcomingEvents = events
    .filter(event => {
      const eventDate = new Date(event.startDate);
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
      return eventDate <= thirtyDaysFromNow && eventDate >= new Date();
    })
    .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  // Get all future events
  const futureEvents = events
    .filter(event => new Date(event.startDate) >= new Date())
    .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  // Get high priority tasks
  const highPriorityTasks = tasks
    .filter(task => task.priority >= 6 && task.status !== 'done')
    .sort((a, b) => b.priority - a.priority);

  // Get maintenance tasks
  const maintenanceTasks = tasks
    .filter(task => task.labels?.includes('maintenance') && task.status !== 'done')
    .sort((a, b) => (b.priority || 0) - (a.priority || 0));

  // Get completed tasks count
  const completedTasksCount = tasks.filter(t => t.status === 'done').length;

  // Determine which sections to show
  const showUpcomingEvents = events.length > 0;
  const showHighPriorityTasks = highPriorityTasks.length > 0;
  const showMaintenanceTasks = maintenanceTasks.length > 0;
  const showVehicleStatus = vehicles.length > 0;

  // Get the most recently updated vehicle
  const primaryVehicle = vehicles.length > 0 
    ? vehicles.reduce((latest, current) => 
        new Date(current.updatedAt) > new Date(latest.updatedAt) ? current : latest
      , vehicles[0])
    : null;

  // Update vehicle-related navigation
  const handleVehicleClick = (vehicleId: string) => {
    navigate(`/app/vehicle/${vehicleId}`);
  };

  // Get the next upcoming event
  const nextEvent = events
    .filter(event => new Date(event.startDate) >= new Date())
    .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())[0];

  // Calculate days until next event
  const getDaysUntilEvent = (event: TrackEvent) => {
    const today = new Date();
    const eventDate = new Date(event.startDate);
    const diffTime = eventDate.getTime() - today.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const vehicle = vehicles[0];

  return (
    <div className="space-y-6">
      {/* Quick Actions Bar - Mobile Optimized */}
      <div className="bg-dark-200 rounded-lg p-4 lg:p-4">
        <div className="flex lg:hidden overflow-x-auto pb-2 -mb-2 scrollbar-hide">
          <div className="flex gap-8 px-2">
            <button
              onClick={() => navigate('/app/tasks/new')}
              className="flex flex-col items-center gap-1 min-w-[4rem]"
            >
              <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                <Plus className="w-6 h-6 text-primary-500" />
              </div>
              <span className="text-xs text-gray-400 whitespace-nowrap">New Task</span>
            </button>
            <button
              onClick={() => navigate('/app/schedule/new', { state: { isNew: true, date: new Date() }})}
              className="flex flex-col items-center gap-1 min-w-[4rem]"
            >
              <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                <Calendar className="w-6 h-6 text-primary-500" />
              </div>
              <span className="text-xs text-gray-400 whitespace-nowrap">Add Event</span>
            </button>
            {!vehicles.length && (
              <button
                onClick={() => navigate('/app/vehicle')}
                className="flex flex-col items-center gap-1 min-w-[4rem]"
              >
                <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                  <Car className="w-6 h-6 text-primary-500" />
                </div>
                <span className="text-xs text-gray-400 whitespace-nowrap">Add Vehicle</span>
              </button>
            )}
            {!budget && (
              <button
                onClick={() => navigate('/app/budget')}
                className="flex flex-col items-center gap-1 min-w-[4rem]"
              >
                <div className="p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors">
                  <Settings className="w-6 h-6 text-primary-500" />
                </div>
                <span className="text-xs text-gray-400 whitespace-nowrap">Set Budget</span>
              </button>
            )}
          </div>
        </div>

        {/* Desktop Quick Actions */}
        <div className="hidden lg:flex flex-wrap gap-4">
          <button
            onClick={() => navigate('/app/tasks/new')}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
          >
            <Plus className="w-4 h-4" />
            New Task
          </button>
          <button
            onClick={() => navigate('/app/schedule/new', { state: { isNew: true, date: new Date() }})}
            className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
          >
            <Plus className="w-4 h-4" />
            Add Event
          </button>
          {!vehicles.length && (
            <button
              onClick={() => navigate('/app/vehicle')}
              className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
            >
              <Plus className="w-4 h-4" />
              Add Vehicle
            </button>
          )}
          {!budget && (
            <button
              onClick={() => navigate('/app/budget')}
              className="flex items-center gap-2 px-4 py-2 bg-dark-100 text-white rounded-md hover:bg-dark-50 transition-colors"
            >
              <Plus className="w-4 h-4" />
              Set Up Budget
            </button>
          )}
        </div>
      </div>

      {!vehicle ? (
        // Show Get Started section if no vehicle
        <div className="bg-dark-200 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Welcome to PitPrep</h2>
          <p className="text-gray-400 mb-8 max-w-lg mx-auto">
            Let's get your motorsport journey started. Follow these steps to set up your profile:
          </p>
          <div className="grid gap-4 max-w-2xl mx-auto">
            <Link
              to="/app/vehicle"
              className="flex items-center justify-between p-4 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
            >
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-primary-500/10 flex items-center justify-center">
                  <span className="text-primary-500 font-semibold">1</span>
                </div>
                <div className="text-left">
                  <h3 className="text-white font-medium group-hover:text-primary-500">Add Your Vehicle</h3>
                  <p className="text-sm text-gray-400">Set up your vehicle specifications and details</p>
                </div>
              </div>
              <ArrowUpRight className="w-5 h-5 text-gray-400 group-hover:text-primary-500" />
            </Link>

            <div className="flex items-center justify-between p-4 bg-dark-100/50 rounded-lg">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-dark-300 flex items-center justify-center">
                  <span className="text-gray-400 font-semibold">2</span>
                </div>
                <div className="text-left">
                  <h3 className="text-gray-300">Set Up Your Budget</h3>
                  <p className="text-sm text-gray-400">Plan your spending and track expenses</p>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between p-4 bg-dark-100/50 rounded-lg">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-dark-300 flex items-center justify-center">
                  <span className="text-gray-400 font-semibold">3</span>
                </div>
                <div className="text-left">
                  <h3 className="text-gray-300">Schedule Your First Event</h3>
                  <p className="text-sm text-gray-400">Add track days or races to your calendar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left Column - Vehicle & Next Event */}
          <div className="space-y-6">
            {/* Vehicle Quick Status */}
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-white">Vehicle Status</h2>
                <Link 
                  to={`/app/vehicle/${vehicle.id}`}
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                >
                  View Details
                  <ChevronRight className="w-4 h-4" />
                </Link>
              </div>
              <div className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg">
                <div className="p-2 bg-primary-500/10 rounded-lg">
                  <Car className="w-5 h-5 text-primary-500" />
                </div>
                <div className="flex-1 min-w-0">
                  <h3 className="text-white font-medium truncate">
                    {vehicle.name || `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                  </h3>
                  <div className="grid grid-cols-2 gap-2 mt-2">
                    <div>
                      <p className="text-xs text-gray-400">Power</p>
                      <p className="text-sm text-white">{vehicle.power} hp</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-400">Weight</p>
                      <p className="text-sm text-white">{vehicle.weight} lbs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Next Event */}
            {nextEvent ? (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">Next Event</h2>
                  <Link 
                    to="/app/schedule"
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    View Schedule
                    <ChevronRight className="w-4 h-4" />
                  </Link>
                </div>
                <Link
                  to={`/app/schedule/${nextEvent.id}`}
                  className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                >
                  <div className="p-2 bg-primary-500/10 rounded-lg">
                    <Calendar className="w-5 h-5 text-primary-500" />
                  </div>
                  <div>
                    <h3 className="text-white font-medium group-hover:text-primary-500">
                      {nextEvent.title}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {format(new Date(nextEvent.startDate), 'MMM d, yyyy')}
                    </p>
                    <p className="text-xs text-primary-500 mt-1">
                      In {getDaysUntilEvent(nextEvent)} days
                    </p>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">Schedule</h2>
                  <button
                    onClick={() => navigate('/app/schedule/new', { state: { isNew: true, date: new Date() }})}
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    Add Event
                    <Plus className="w-4 h-4" />
                  </button>
                </div>
                <div className="text-center py-8">
                  <Calendar className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                  <p className="text-gray-400">No upcoming events</p>
                  <button
                    onClick={() => navigate('/app/schedule/new', { state: { isNew: true, date: new Date() }})}
                    className="mt-4 text-primary-500 hover:text-primary-400"
                  >
                    Schedule your first event
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Middle Column - Tasks & Maintenance */}
          <div className="space-y-6">
            {/* Maintenance Tasks */}
            <div className="bg-dark-200 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-white">Maintenance</h2>
                <button
                  onClick={() => navigate('/app/tasks/new', { state: { defaultLabel: 'maintenance' } })}
                  className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                >
                  Add Task
                  <Plus className="w-4 h-4" />
                </button>
              </div>
              {maintenanceTasks.length > 0 ? (
                <div className="space-y-3">
                  {maintenanceTasks.slice(0, 3).map(task => (
                    <Link
                      key={task.id}
                      to={`/app/tasks/${task.id}`}
                      className="flex items-start gap-3 p-3 bg-dark-100 rounded-lg hover:bg-dark-50 transition-colors group"
                    >
                      <div className="p-2 bg-yellow-500/10 rounded-lg">
                        <Wrench className="w-5 h-5 text-yellow-500" />
                      </div>
                      <div>
                        <h3 className="text-white font-medium group-hover:text-primary-500">
                          {task.title}
                        </h3>
                        <p className="text-sm text-gray-400">
                          {task.dueDate ? format(new Date(task.dueDate), 'MMM d, yyyy') : 'No due date'}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8">
                  <Wrench className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                  <p className="text-gray-400">No maintenance tasks</p>
                  <button
                    onClick={() => navigate('/app/tasks/new', { state: { defaultLabel: 'maintenance' } })}
                    className="mt-4 text-primary-500 hover:text-primary-400"
                  >
                    Add maintenance task
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Right Column - Budget & Stats */}
          <div className="space-y-6">
            {/* Budget Overview */}
            {budget ? (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">Budget</h2>
                  <Link 
                    to="/app/budget"
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    View Details
                    <ChevronRight className="w-4 h-4" />
                  </Link>
                </div>
                <div className="space-y-4">
                  <div className="p-3 bg-dark-100 rounded-lg">
                    <p className="text-sm text-gray-400">Monthly Budget</p>
                    <p className="text-xl font-semibold text-white">
                      ${budget.monthlyBudget?.toLocaleString()}
                    </p>
                  </div>
                  <div className="p-3 bg-dark-100 rounded-lg">
                    <p className="text-sm text-gray-400">Spent This Month</p>
                    <p className="text-xl font-semibold text-white">
                      ${budget.spentThisMonth?.toLocaleString() || '0'}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-dark-200 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold text-white">Budget</h2>
                  <Link 
                    to="/app/budget"
                    className="text-sm text-primary-500 hover:text-primary-400 flex items-center gap-1"
                  >
                    Set Up
                    <Plus className="w-4 h-4" />
                  </Link>
                </div>
                <div className="text-center py-8">
                  <DollarSign className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                  <p className="text-gray-400">No budget set up</p>
                  <Link
                    to="/app/budget"
                    className="mt-4 text-primary-500 hover:text-primary-400 inline-block"
                  >
                    Set up your budget
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
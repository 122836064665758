import React from 'react';
import SetupInput from '../SetupInput';
import SetupInputGrid from '../SetupInputGrid';
import { SETUP_RANGES } from '../../../../constants/setup';
import type { VehicleSetup } from '../../../../types/vehicle';
import type { SetupChangeHandler } from '../VehicleSetup';

interface AeroSetupProps {
  setup: VehicleSetup['aero'];
  onChange: SetupChangeHandler;
}

export default function AeroSetup({ setup, onChange }: AeroSetupProps) {
  return (
    <SetupInputGrid>
      <SetupInput
        label="Wing Angle"
        value={setup.wingAngle}
        onChange={(value, isTemp) => onChange('wingAngle', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.aero.wingAngle.min}
        max={SETUP_RANGES.aero.wingAngle.max}
        step={SETUP_RANGES.aero.wingAngle.step}
        unit={SETUP_RANGES.aero.wingAngle.unit}
      />
      <SetupInput
        label="Splitter Height"
        value={setup.splitterHeight}
        onChange={(value, isTemp) => onChange('splitterHeight', value as number, isTemp)}
        type="slider"
        min={SETUP_RANGES.aero.splitterHeight.min}
        max={SETUP_RANGES.aero.splitterHeight.max}
        step={SETUP_RANGES.aero.splitterHeight.step}
        unit={SETUP_RANGES.aero.splitterHeight.unit}
        description="Lower height increases front downforce but risks damage"
      />
    </SetupInputGrid>
  );
} 
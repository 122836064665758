import React from 'react';
import SetupInput from '../SetupInput';
import SetupInputGrid from '../SetupInputGrid';
import { SETUP_RANGES } from '../../../../constants/setup';
import type { VehicleSetup } from '../../../../types/vehicle';

interface WeightSetupProps {
  setup: VehicleSetup['weight'];
  onChange: (field: string, value: number) => void;
}

export default function WeightSetup({ setup, onChange }: WeightSetupProps) {
  return (
    <div>
      <SetupInput
        label="Ballast Weight"
        value={setup.ballast}
        onChange={(value) => onChange('ballast', Number(value))}
        type="slider"
        min={SETUP_RANGES.weight.ballast.min}
        max={SETUP_RANGES.weight.ballast.max}
        step={SETUP_RANGES.weight.ballast.step}
        unit={SETUP_RANGES.weight.ballast.unit}
        description="Additional weight added to meet minimum weight requirements"
      />
    </div>
  );
} 
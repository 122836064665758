import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from '../types/auth';
import { 
  GoogleAuthProvider, 
  signInWithPopup, 
  onAuthStateChanged, 
  signOut,
  deleteUser
} from 'firebase/auth';
import { auth, db } from '../config/firebase';
import { doc, getDoc, runTransaction, collection, deleteDoc, updateDoc } from 'firebase/firestore';
import { redirectToCheckout } from '../services/stripeService';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  programId: string | null;
  loginWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  deleteAccount: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [programId, setProgramId] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!firebaseUser) {
        setUser(null);
        setProgramId(null);
        setIsLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
        const userData = userDoc.data();

        if (!userData) {
          setIsLoading(false);
          return;
        }

        setUser({
          id: firebaseUser.uid,
          email: firebaseUser.email || '',
          displayName: firebaseUser.displayName || '',
          photoURL: firebaseUser.photoURL || '',
          subscription: userData.subscription || null,
          createdAt: firebaseUser.metadata.creationTime || undefined,
        });
        setProgramId(userData.programs?.[0] || null);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUser(null);
        setProgramId(null);
      }

      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      const result = await signInWithPopup(auth, provider);

      const userRef = doc(db, 'users', result.user.uid);
      const userSnap = await getDoc(userRef);
      
      if (!userSnap.exists()) {
        const now = new Date().toISOString();
        await runTransaction(db, async (transaction) => {
          const programsCollectionRef = collection(db, 'programs');
          const programDoc = doc(programsCollectionRef);
          
          transaction.set(programDoc, {
            createdAt: now,
            ownerId: result.user.uid
          });

          transaction.set(userRef, {
            email: result.user.email,
            displayName: result.user.displayName,
            photoURL: result.user.photoURL,
            createdAt: now,
            programs: [programDoc.id],
            subscription: { status: null }
          });
        });

        await redirectToCheckout();
      } else {
        // Handle returning users with no subscription
        const userData = userSnap.data();
        if (!userData.subscription?.status) {
          // Reset subscription state and redirect to checkout
          await updateDoc(userRef, {
            subscription: { status: null }
          });
          await redirectToCheckout();
        }
      }
    } catch (error) {
      console.error('Error in loginWithGoogle:', error);
      if (auth.currentUser) {
        try {
          await deleteUser(auth.currentUser);
        } catch (deleteError) {
          console.error('Failed to delete auth user:', deleteError);
        }
      }
      throw new Error('Failed to complete signup. Please try again.');
    }
  };

  const logout = () => signOut(auth);

  const deleteAccount = async () => {
    if (!auth.currentUser) throw new Error('No user signed in');
    
    try {
      // 1. Cancel Stripe subscription
      const functions = getFunctions();
      const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
      await cancelSubscription();

      // 2. Delete Firestore data
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userData = (await getDoc(userRef)).data();
      
      if (userData?.programs?.length) {
        // Delete program and all its subcollections
        await runTransaction(db, async (transaction) => {
          const programRef = doc(db, 'programs', userData.programs[0]);
          transaction.delete(programRef);
          transaction.delete(userRef);
        });
      } else {
        // Just delete user document if no program
        await deleteDoc(userRef);
      }

      // 3. Finally delete the auth account
      await deleteUser(auth.currentUser);
    } catch (error) {
      console.error('Error deleting account:', error);
      throw new Error('Failed to delete account. Please try again.');
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        isLoading,
        programId,
        loginWithGoogle,
        logout,
        deleteAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
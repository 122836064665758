import React, { useState } from 'react';
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, useSensor, useSensors, PointerSensor, useDroppable, DragOverEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import TaskColumn from './TaskColumn';
import TaskCard from './TaskCard';
import { Task, TaskStatus } from '../../types/task';
import { Trash2 } from 'lucide-react';

interface TaskBoardProps {
  tasks: Task[];
  onStatusChange: (taskId: string, newStatus: TaskStatus) => void;
  onReorder: (tasks: Task[]) => void;
  onDeleteTask: (taskId: string) => Promise<void>;
}

const columns: { id: TaskStatus; title: string }[] = [
  { id: 'someday', title: 'Someday' },
  { id: 'ready', title: 'Ready' },
  { id: 'on-track', title: 'On Track' },
  { id: 'pit-stop', title: 'Pit Stop' },
  { id: 'done', title: 'Done' }
];

function DeleteZone() {
  const { setNodeRef, isOver } = useDroppable({
    id: 'delete'
  });

  return (
    <div ref={setNodeRef}>
      <div 
        className={`w-64 h-24 border-2 border-dashed rounded-lg flex items-center justify-center transition-all duration-200 ${
          isOver 
            ? 'border-red-500 bg-red-500/20 scale-110' 
            : 'border-red-500 bg-red-500/10'
        }`}
      >
        <Trash2 className={`w-6 h-6 transition-all duration-200 ${
          isOver ? 'text-red-500 scale-125' : 'text-red-400'
        }`} />
      </div>
    </div>
  );
}

export default function TaskBoard({ tasks, onStatusChange, onReorder, onDeleteTask }: TaskBoardProps) {
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const [showDeleteZone, setShowDeleteZone] = useState(false);
  
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    const task = tasks.find(t => t.id === event.active.id);
    if (task) {
      setActiveTask(task);
      setShowDeleteZone(true);
    }
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over) {
      setActiveTask(null);
      setShowDeleteZone(false);
      return;
    }

    const activeTask = tasks.find(t => t.id === active.id);
    
    if (over.id === 'delete' && activeTask) {
      try {
        await onDeleteTask(activeTask.id);
      } catch (err) {
        console.error('Error deleting task:', err);
      }
    } else {
      const overColumn = columns.find(col => col.id === over.id);
      if (activeTask && overColumn) {
        onStatusChange(activeTask.id, overColumn.id);
      } else if (activeTask && over.id !== active.id) {
        const oldIndex = tasks.findIndex(t => t.id === active.id);
        const newIndex = tasks.findIndex(t => t.id === over.id);
        onReorder(arrayMove(tasks, oldIndex, newIndex));
      }
    }

    setActiveTask(null);
    setShowDeleteZone(false);
  };

  const handleDragCancel = () => {
    setActiveTask(null);
    setShowDeleteZone(false);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
        {columns.map(column => (
          <TaskColumn
            key={column.id}
            id={column.id}
            title={column.title}
            tasks={tasks.filter(task => task.status === column.id)}
          />
        ))}
      </div>

      {/* Delete Zone */}
      {showDeleteZone && (
        <div className="fixed bottom-8 right-8">
          <DeleteZone />
        </div>
      )}

      <DragOverlay>
        {activeTask ? <TaskCard task={activeTask} /> : null}
      </DragOverlay>
    </DndContext>
  );
}
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Edit, ArrowLeft, Plus, Trash2, Loader2, ChevronDown, Edit2, Save, X } from 'lucide-react';
import { doc, getDoc, updateDoc, collection, addDoc, deleteDoc, setDoc, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Vehicle, VehicleSetup } from '../types/vehicle';
import VehicleSetupComponent from '../components/vehicles/setup/VehicleSetup';
import VehicleModifications from '../components/vehicles/modifications/VehicleModifications';
import type { Part, PartMetadata } from '../types/part';
import { isConsumable, getInstalledParts, CONSUMABLE_TYPES } from '../types/part';
import { getSetupSummary } from '../components/vehicles/setup/VehicleSetup';
import ConsumablesStatus from '../components/vehicles/consumables/ConsumablesStatus';
import RecommendedMaintenance from '../components/vehicles/consumables/RecommendedMaintenance';
import PartsTable from '../components/parts/PartsTable';
import { toast } from 'react-hot-toast';
import PartSelect from '../components/vehicles/parts/PartSelect';

export const createInitialSetup = () => ({
  alignment: {
    frontCamber: 0,
    rearCamber: 0,
    frontToe: 0,
    rearToe: 0,
    caster: 0
  },
  suspension: {
    frontRideHeight: 0,
    rearRideHeight: 0,
    frontSpringRate: 0,
    rearSpringRate: 0,
    frontRebound: 0,
    rearRebound: 0,
    frontCompression: 0,
    rearCompression: 0,
    frontBarSetting: null,
    rearBarSetting: null
  },
  aero: {
    frontDownforce: 0,
    rearDownforce: 0,
    wingAngle: 0,
    splitterHeight: 0,
    rideHeight: 0
  },
  tires: {
    compound: 'Unknown',
    frontPressureCold: 0,
    rearPressureCold: 0,
    frontPressureHot: 0,
    rearPressureHot: 0
  },
  brakes: {
    brakeBias: 50,
    frontPressure: 0,
    rearPressure: 0,
    padType: 'Unknown'
  },
  differential: {
    frontPreload: null,
    rearPreload: null,
    centerPreload: null,
    frontRampAngle: null,
    rearRampAngle: null,
    lockingPercentage: null
  },
  gearing: {
    finalDrive: 0,
    gearRatios: []
  },
  weight: {
    cornerWeights: {
      frontLeft: 0,
      frontRight: 0,
      rearLeft: 0,
      rearRight: 0
    },
    ballast: 0,
    ballastPosition: 'N/A',
    totalWeight: 0,
    distribution: {
      frontToRear: 50,
      leftToRight: 50
    }
  },
  cooling: {
    targetTemp: 90,
    fanOnTemp: 95,
    thermostatTemp: 82
  },
  engine: {
    idleRpm: 800,
    revLimit: 7000,
    fuelPressure: 3.0,
    boostPressure: 0
  },
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString()
});

const mockConsumables = [
  {
    type: 'Brake Pads',
    wearPercentage: 30,
    expectedReplacement: '2024-06-15',
    brand: 'Ferodo DS2500',
    installedAt: '2024-01-15'
  },
  {
    type: 'Tires',
    wearPercentage: 45,
    expectedReplacement: '2024-05-20',
    brand: 'Michelin PS4S',
    installedAt: '2023-11-01'
  },
  {
    type: 'Engine Oil',
    wearPercentage: 60,
    expectedReplacement: '2024-04-01',
    brand: 'Motul 300V',
    installedAt: '2023-12-15'
  },
  {
    type: 'Brake Fluid',
    wearPercentage: 20,
    expectedReplacement: '2024-08-01',
    brand: 'Motul RBF 660',
    installedAt: '2023-08-01'
  }
];

type VehicleInstallations = NonNullable<Vehicle['installations']>;

interface VehicleDetailProps {
  onUpdateVehicle: (vehicle: Vehicle) => Promise<void>;
}

export default function VehicleDetail({ onUpdateVehicle }: VehicleDetailProps) {
  const { id: routeId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { programId } = useAuth();
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [vehicleParts, setVehicleParts] = useState<Part[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isEditingPower, setIsEditingPower] = useState(false);
  const [isEditingWeight, setIsEditingWeight] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState<Record<string, boolean>>({});
  const [selectedPartForChange, setSelectedPartForChange] = useState<{
    part: Part;
    alternatives: Part[];
  } | null>(null);
  const [isEditingInitialPrice, setIsEditingInitialPrice] = useState(false);

  const vehicleId = routeId;

  useEffect(() => {
    async function fetchVehicle() {
      if (!programId || !vehicleId) return;

      try {
        const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
        const setupRef = doc(db, 'programs', programId, 'vehicles', vehicleId, 'setups', 'default');
        
        const [vehicleSnap, setupSnap] = await Promise.all([
          getDoc(vehicleRef),
          getDoc(setupRef)
        ]);
        
        if (vehicleSnap.exists()) {
          let setupData;
          
          if (!setupSnap.exists()) {
            // Create initial setup if it doesn't exist
            const initialSetup = createInitialSetup();
            await setDoc(setupRef, initialSetup);
            setupData = initialSetup;
          } else {
            setupData = setupSnap.data();
          }
          
          const vehicleData = { 
            id: vehicleSnap.id, 
            ...vehicleSnap.data(),
            setup: setupData
          } as Vehicle;
          
          setVehicle(vehicleData);
        } else {
          setError('Vehicle not found');
        }
      } catch (err) {
        console.error('Error fetching vehicle:', err);
        setError('Failed to load vehicle');
      } finally {
        setIsLoading(false);
      }
    }

    fetchVehicle();
  }, [programId, vehicleId]);

  useEffect(() => {
    if (!programId || !vehicleId) return;

    const partsRef = collection(db, 'programs', programId, 'parts');
    const q = query(partsRef, where('vehicleId', '==', vehicleId));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const parts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Part[];
      setVehicleParts(parts);
    });

    return () => unsubscribe();
  }, [programId, vehicleId]);

  const handleUpdateVehicle = async (updates: Partial<Vehicle>) => {
    if (!programId || !vehicleId) return;

    // Optimistically update local state first
    setVehicle(prev => prev ? { ...prev, ...updates } : null);

    try {
      const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
      
      // If we're updating consumables, ensure it exists and merge properly
      if (updates.consumables) {
        const currentDoc = await getDoc(vehicleRef);
        const currentData = currentDoc.data();
        
        // Filter out any undefined values from the consumables update
        const filteredUpdates = Object.fromEntries(
          Object.entries(updates.consumables)
            .filter(([_, value]) => value !== undefined)
        );

        await updateDoc(vehicleRef, {
          consumables: {
            ...(currentData?.consumables || {}),
            ...filteredUpdates
          },
          updatedAt: new Date().toISOString()
        });
      } else {
        await updateDoc(vehicleRef, {
          ...updates,
          updatedAt: new Date().toISOString()
        });
      }
    } catch (err) {
      // Revert optimistic update on error
      setVehicle(prev => {
        if (!prev) return null;
        // If updating consumables, only revert those changes
        if (updates.consumables && prev.consumables) {
          const prevConsumables = prev.consumables;
          const revertedUpdates = Object.fromEntries(
            Object.entries(prevConsumables)
              .filter(([key]) => updates.consumables && key in updates.consumables)
          );

          return {
            ...prev,
            consumables: {
              ...prevConsumables,
              ...revertedUpdates
            }
          };
        }
        // Otherwise revert all changes
        return prev;
      });
      console.error('Error updating vehicle:', err);
      setError('Failed to update vehicle');
    }
  };

  const handleUpdateSetup = async (updatedSetup: VehicleSetup) => {
    if (!vehicle || !programId || !vehicleId) return;

    try {
      // Reference to the setup document in the setups subcollection
      const setupRef = doc(db, 'programs', programId, 'vehicles', vehicleId, 'setups', 'default');
      
      // Update the setup in Firestore
      await updateDoc(setupRef, {
        ...updatedSetup,
        updatedAt: new Date().toISOString()
      });

      // Update local state
      setVehicle(prev => prev ? {
        ...prev,
        setup: updatedSetup
      } : null);
    } catch (err) {
      console.error('Error updating setup:', err);
      setError('Failed to update setup');
    }
  };

  const handleDeleteVehicle = async () => {
    if (!programId || !vehicleId) return;
    
    try {
      // Delete the setup document first
      const setupRef = doc(db, 'programs', programId, 'vehicles', vehicleId, 'setups', 'default');
      await deleteDoc(setupRef);

      // Delete any parts associated with this vehicle
      const partsRef = collection(db, 'programs', programId, 'parts');
      const partsQuery = query(partsRef, where('vehicleId', '==', vehicleId));
      const partsSnapshot = await getDocs(partsQuery);
      const deleteParts = partsSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deleteParts);

      // Delete any events associated with this vehicle
      const eventsRef = collection(db, 'programs', programId, 'events');
      const eventsQuery = query(eventsRef, where('vehicleId', '==', vehicleId));
      const eventsSnapshot = await getDocs(eventsQuery);
      const deleteEvents = eventsSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deleteEvents);

      // Finally delete the vehicle document itself
      await deleteDoc(doc(db, 'programs', programId, 'vehicles', vehicleId));
      navigate('/app/vehicle');
    } catch (error) {
      console.error('Error deleting vehicle:', error);
      toast.error('Failed to delete vehicle');
    }
  };

  const handleUpdatePartMetadata = async (partId: string, metadata: Partial<PartMetadata>) => {
    if (!programId || !vehicleId) return;

    try {
      // Find the part to update
      const partIndex = vehicleParts.findIndex(p => p.id === partId);
      if (partIndex === -1) return;

      // Create updated part with merged metadata
      const updatedPart = {
        ...vehicleParts[partIndex],
        metadata: {
          ...vehicleParts[partIndex].metadata,
          ...metadata
        }
      };

      // Update part in Firestore
      const partRef = doc(db, 'programs', programId, 'parts', partId);
      await updateDoc(partRef, {
        metadata: updatedPart.metadata,
        updatedAt: new Date().toISOString()
      });

      // Update local state
      const newParts = [...vehicleParts];
      newParts[partIndex] = updatedPart;
      setVehicleParts(newParts);

    } catch (error) {
      console.error('Error updating part metadata:', error);
      toast.error('Failed to update part');
    }
  };

  const handleInstallPart = async (partId: string | null, slot: keyof VehicleInstallations) => {
    if (!vehicle || !programId || !vehicleId) return;

    try {
      // Create new installations object with the update
      const newInstallations = {
        ...vehicle.installations,
        [slot]: partId
      };

      // Update vehicle in Firestore
      const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
      await updateDoc(vehicleRef, {
        installations: newInstallations,
        updatedAt: new Date().toISOString()
      });

      // Update local state
      setVehicle(prev => prev ? {
        ...prev,
        installations: newInstallations
      } : null);

    } catch (error) {
      console.error('Error installing part:', error);
      toast.error('Failed to install part');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary-500" />
      </div>
    );
  }

  if (error || !vehicle) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-400">{error || 'Vehicle not found.'}</p>
        <button
          onClick={() => navigate('/app/vehicle')}
          className="mt-4 text-primary-500 hover:text-primary-400"
        >
          Return to Vehicle
        </button>
      </div>
    );
  }

  const calculatePowerToWeight = () => {
    if (!vehicle) return '0.00';
    return (vehicle.weight / vehicle.power).toFixed(2);
  };

  const totalSpent = vehicleParts.reduce((sum, part) => sum + (part.cost || 0), 0);

  const setupSummary = getSetupSummary(vehicle?.setup);
  const partsCount = vehicleParts.length;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <button
            onClick={() => navigate('/app/dashboard')}
            className="p-2 text-gray-400 hover:text-primary-500 rounded-lg hover:bg-dark-50"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <h1 className="text-2xl font-bold text-white">Vehicle Details</h1>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-dark-200 rounded-lg p-6 max-w-md w-full space-y-4">
            <h3 className="text-xl font-bold text-white">Delete Vehicle</h3>
            <p className="text-gray-400">
              Are you sure you want to delete this vehicle? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-gray-400 hover:text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteVehicle}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Vehicle Summary */}
      <div className="bg-dark-200 rounded-lg p-6">
        <div className="flex justify-between items-start mb-6">
          <div className="space-y-1">
            <h2 className="text-xl font-semibold text-white">{vehicle.year} {vehicle.make} {vehicle.model}</h2>
            {vehicle.nickname && (
              <p className="text-gray-400 text-sm">"{vehicle.nickname}"</p>
            )}
            <div className="flex items-center gap-2 text-sm text-gray-400">
              <span>{vehicle.drivetrain}</span>
              <span>•</span>
              <span>{vehicle.fuelType}</span>
              {vehicle.categories?.length > 0 && (
                <>
                  <span>•</span>
                  <span>{vehicle.categories[0]}</span>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="text-gray-400 hover:text-red-500"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 md:gap-6">
          {/* Performance Stats */}
          <div className="bg-dark-100/50 rounded-lg p-4 space-y-3">
            <h3 className="text-sm font-medium text-gray-400">Performance</h3>
            <div className="space-y-2">
              <div className="group relative flex items-center justify-between">
                <span className="text-sm text-gray-400">Power</span>
                <div className="flex items-center gap-2">
                  {isEditingPower ? (
                    <input
                      type="number"
                      value={vehicle.power || ''}
                      onChange={async (e) => {
                        if (!programId || !vehicleId) return;
                        const power = parseInt(e.target.value);
                        if (!isNaN(power)) {
                          const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
                          await updateDoc(vehicleRef, { power, updatedAt: new Date().toISOString() });
                          setVehicle(prev => prev ? { ...prev, power } : null);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setIsEditingPower(false);
                        }
                        if (e.key === 'Escape') {
                          setIsEditingPower(false);
                        }
                      }}
                      onBlur={() => setIsEditingPower(false)}
                      placeholder="0"
                      className="w-16 bg-dark-200 text-white border border-dark-300 rounded px-2 py-0.5 text-sm focus:outline-none focus:border-primary-500"
                      autoFocus
                    />
                  ) : (
                    <button
                      onClick={() => setIsEditingPower(true)}
                      className="flex items-center gap-2 px-2 py-0.5 rounded bg-dark-200/0 hover:bg-dark-200 @[hover:none]:bg-dark-200/50 group"
                    >
                      <span className="text-sm font-medium text-white group-hover:border-b group-hover:border-dashed group-hover:border-gray-400 @[hover:none]:border-b @[hover:none]:border-dashed @[hover:none]:border-gray-400">{vehicle.power || 0}</span>
                      <span className="text-sm text-gray-400">hp</span>
                    </button>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Weight</span>
                <div className="flex items-center gap-2">
                  {isEditingWeight ? (
                    <input
                      type="number"
                      value={vehicle.weight || ''}
                      onChange={async (e) => {
                        if (!programId || !vehicleId) return;
                        const weight = parseInt(e.target.value);
                        if (!isNaN(weight)) {
                          const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
                          await updateDoc(vehicleRef, { weight, updatedAt: new Date().toISOString() });
                          setVehicle(prev => prev ? { ...prev, weight } : null);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setIsEditingWeight(false);
                        }
                        if (e.key === 'Escape') {
                          setIsEditingWeight(false);
                        }
                      }}
                      onBlur={() => setIsEditingWeight(false)}
                      placeholder="0"
                      className="w-16 bg-dark-200 text-white border border-dark-300 rounded px-2 py-0.5 text-sm focus:outline-none focus:border-primary-500"
                      autoFocus
                    />
                  ) : (
                    <button
                      onClick={() => setIsEditingWeight(true)}
                      className="flex items-center gap-2 px-2 py-0.5 rounded bg-dark-200/0 hover:bg-dark-200 @[hover:none]:bg-dark-200/50 group"
                    >
                      <span className="text-sm font-medium text-white group-hover:border-b group-hover:border-dashed group-hover:border-gray-400 @[hover:none]:border-b @[hover:none]:border-dashed @[hover:none]:border-gray-400">{vehicle.weight || 0}</span>
                      <span className="text-sm text-gray-400">lbs</span>
                    </button>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Power/Weight</span>
                <span className="text-sm text-white">{calculatePowerToWeight()} lb/hp</span>
              </div>
            </div>
          </div>

          {/* Financial Summary */}
          <div className="bg-dark-100/50 rounded-lg p-4 space-y-3">
            <h3 className="text-sm font-medium text-gray-400">Financial Summary</h3>
            <div className="space-y-2">
              <div className="group relative flex items-center justify-between">
                <span className="text-sm text-gray-400">Initial Cost</span>
                <div className="flex items-center gap-2">
                  {isEditingInitialPrice ? (
                    <div className="flex items-center gap-1">
                      <span className="text-sm text-gray-400">$</span>
                      <input
                        type="number"
                        value={vehicle.initialPrice || ''}
                        onChange={async (e) => {
                          if (!programId || !vehicleId) return;
                          const initialPrice = parseInt(e.target.value);
                          if (!isNaN(initialPrice)) {
                            const vehicleRef = doc(db, 'programs', programId, 'vehicles', vehicleId);
                            await updateDoc(vehicleRef, { initialPrice, updatedAt: new Date().toISOString() });
                            setVehicle(prev => prev ? { ...prev, initialPrice } : null);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setIsEditingInitialPrice(false);
                          }
                          if (e.key === 'Escape') {
                            setIsEditingInitialPrice(false);
                          }
                        }}
                        onBlur={() => setIsEditingInitialPrice(false)}
                        placeholder="0"
                        className="w-20 bg-dark-200 text-white border border-dark-300 rounded px-2 py-0.5 text-sm focus:outline-none focus:border-primary-500"
                        autoFocus
                      />
                    </div>
                  ) : (
                    <button
                      onClick={() => setIsEditingInitialPrice(true)}
                      className="flex items-center gap-1 px-2 py-0.5 rounded bg-dark-200/0 hover:bg-dark-200 @[hover:none]:bg-dark-200/50 group"
                    >
                      <span className="text-sm text-gray-400">$</span>
                      <span className="text-sm font-medium text-white group-hover:border-b group-hover:border-dashed group-hover:border-gray-400 @[hover:none]:border-b @[hover:none]:border-dashed @[hover:none]:border-gray-400">
                        {(vehicle.initialPrice || 0).toLocaleString()}
                      </span>
                    </button>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Parts Investment</span>
                <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-400">$</span>
                  <span className="text-sm text-white">{totalSpent.toLocaleString()}</span>
                </div>
              </div>

              <div className="flex items-center justify-between border-t border-dark-300 pt-2 mt-2">
                <span className="text-sm font-medium text-gray-400">Total Investment</span>
                <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-400">$</span>
                  <span className="text-sm font-medium text-white">{(totalSpent + (vehicle.initialPrice || 0)).toLocaleString()}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Vehicle Setup Summary */}
          <div className="bg-dark-100/50 rounded-lg p-4 space-y-3">
            <h3 className="text-sm font-medium text-gray-400">Vehicle Setup</h3>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Last Updated</span>
                <span className="text-sm text-white">
                  {vehicle.setup?.updatedAt ? new Date(vehicle.setup.updatedAt).toLocaleDateString() : 'Never'}
                </span>
              </div>
            </div>
          </div>

          {/* Quick Stats */}
          <div className="bg-dark-100/50 rounded-lg p-4 space-y-3">
            <h3 className="text-sm font-medium text-gray-400">Quick Stats</h3>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Total Parts</span>
                <span className="text-sm text-white">{vehicleParts.length}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Primary Use</span>
                <span className="text-sm text-white">{vehicle.primaryUse}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-400">Last Updated</span>
                <span className="text-sm text-white">
                  {new Date(vehicle.updatedAt).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Vehicle Setup */}
        <div className="mt-6 pt-6 border-t border-dark-100">
          <div className="mb-6">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-2xl font-bold text-white">Setup</h2>
                <p className="text-sm text-gray-400">Configure your vehicle's setup for optimal performance</p>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <VehicleSetupComponent 
              setup={vehicle?.setup}
              vehicle={vehicle}
              parts={vehicleParts}
              onInstallPart={handleInstallPart}
              onUpdatePartMetadata={handleUpdatePartMetadata}
              onUpdateVehicle={handleUpdateVehicle}
              onUpdateSetup={handleUpdateSetup}
            />
          </div>
        </div>

        {/* Consumables Status has been moved to the Setup categories */}

        {/* Inventory */}
        <div className="mt-6 pt-6 border-t border-dark-100">
          <h3 className="text-lg font-medium text-white mb-4">Inventory</h3>
          <PartsTable 
            parts={vehicleParts}
            vehicleId={vehicleId}
            vehicle={vehicle}
            mode="installed"
          />
        </div>
      </div>
    </div>
  );
}